<template>
	<form class="c-form-signup" @submit.prevent="onSubmit">
		<Form ref="form" :schema="computedSchema" v-model="form" @status="saveStatus" buttonText="Start" :errors="errors" :processing="processing"></Form>
	</form>
</template>

<script>
import Form from './index.vue';

let schema = {
	name: {
		component: 'InputText',
		label: 'Full name',
		placeholder: '',
		properties: {
			columnSize: 6,
		},
		validations: {
			required: {
				params: null,
				message: 'This field is required',
			},
			minLength: {
				params: 2,
				message: "Your name can't be that short, surely?",
			},
			maxLength: {
				params: 60,
				message: `Whoa, that's a long name. Use the name your friends call you by?`,
			},
		},
	},
	// lastName: {
	// 	component: 'InputText',
	// 	label: 'Last name',
	// 	placeholder: '',
	// 	properties: {
	// 		columnSize: 6,
	// 	},
	// 	validations: {
	// 		required: {
	// 			params: null,
	// 			message: 'This field is required',
	// 		},
	// 		minLength: {
	// 			params: 1,
	// 			message: "Your last name can't be that short, surely?",
	// 		},
	// 		maxLength: {
	// 			params: 40,
	// 			message: `Whoa, that's a long name. Use the name your friends call you by?`,
	// 		},
	// 	},
	// },
	// workspaceName: {
	// 	component: 'InputText',
	// 	label: 'Company name',
	// 	placeholder: '',
	// 	description: 'If not sure, put your eComm shop name instead.',
	// 	properties: {
	// 		columnSize: 6,
	// 	},
	// 	validations: {
	// 		required: {
	// 			params: null,
	// 			message: 'This field is required',
	// 		},
	// 		minLength: {
	// 			params: 1,
	// 			message: "Company name can't be that short, surely?",
	// 		},
	// 		maxLength: {
	// 			params: 40,
	// 			message: `Whoa, that's a long name. Sure that's your company's name`,
	// 		},
	// 	},
	// },
	email: {
		component: 'InputEmail',
		label: 'Your email',
		placeholder: 'email@gmail.com',
		description: `Please use your company email for best results. Disposable emails aren't supported.`,
		properties: {
			columnSize: 6,
		},
		validations: {
			required: {
				params: null,
				message: 'This field is required',
			},
			minLength: {
				params: 4,
				message: 'Use a proper email',
			},
			maxLength: {
				params: 48,
				message: `Whoa, that's a loong email. Use another one?`,
			},
		},
	},
	password: {
		component: 'InputPassword',
		label: 'Password',
		placeholder: '********',
		description: 'Make sure your password is at least 8 letters.',
		properties: {
			columnSize: 6,
		},
		validations: {
			required: {
				params: null,
				message: 'This field is required',
			},
			minLength: {
				params: 8,
				message: 'Password should be at least 8 characters',
			},
			maxLength: {
				params: 40,
				message: `That's a looong password. Make it short ya'll.`,
			},
		},
	},
	terms_confirmation: {
		component: 'InputCheckboxSimple',
		label: `I agree to the <a href="https://swipekit.app/terms" target="_blank">terms & conditions</a>`,
		validations: {
			required: {
				params: null,
				message: "You need to agree to Swipekit's terms & conditions.",
			},
		},
	},
};

export default {
	components: {
		Form,
	},

	data: function () {
		return {
			processing: false,
			schema: schema,
			form: {
				email: '',
				password: '',
			},
			status: null,
			errors: [],
		};
	},

	props: {
		presetEmail: {},
	},

	watch: {
		presetEmail: function () {
			if (this.presetEmail) {
				this.form.email = this.presetEmail;
			}
		},
	},

	computed: {
		trialExtension: function () {
			return this.$store.getters['app/trialExtension'];
		},
		referralPartner: function () {
			return this.$store.getters['app/referralPartner'];
		},
		computedSchema: function () {
			let s = schema;

			if (this.presetEmail) {
				s.email.readonly = true;
			}

			return s;
		},
	},

	methods: {
		saveStatus: function (saveStatus) {
			this.status = saveStatus;
		},
		async onSubmit() {
			this.$refs.form.validate();

			if (this.status.invalid) {
				return;
			}

			if (!this.form.terms_confirmation) {
				return;
			}

			delete this.form.terms_confirmation;

			this.processing = true;

			let form = this.form;

			if (this.$store.getters['affiliateId']) {
				form.affiliateId = this.$store.getters['affiliateId'];
			}

			if (this.referralPartner) {
				form.referralPartner = this.referralPartner;
			}

			try {
				let pie = await this.$store.dispatch('users/signup', form);
				//let pie = await this.$store.dispatch("users/getPie");

				await this.$store.dispatch('afterLogin', pie);
				await this.$store.dispatch('message/onSignup');
				this.$store.dispatch('app/toggleOnboarding', true);
				this.$emit('onSubmit');
			} catch (err) {
				//console.log(err);
				if (typeof err === 'object' && err.message) {
					this.errors = [];
					this.errors.push({
						message: err.message,
						type: 'GENERIC',
					});
				}
				if (err.length > 0) {
					this.errors = err;
				}
			}

			this.processing = false;
		},
	},
};
</script>

<style></style>
