import http from '@swipekit/lib/http.js';
import { v4 as uuidv4 } from 'uuid';
import api from '@swipekit/lib/api.js';

var store = {
	namespaced: true,
	state: {
		env: 'development',
		baseUrl: 'http://localhost:4000',
		version: '0.0.0',
		mode: 'WEBAPP',
		browser: 'Other',
		os: 'Unknown',
		timezone: '',
		screenX: 0,
		screenY: 0,
		sessionId: '',
		init: false,
		loaded: false,
		onboarding: false,
		firstRun: false,
		assetUrl: 'https://pub-f8eca917c8314f7fadb99596cbd09991.r2.dev',
	},
	getters: {
		init: function (state) {
			return state.init;
		},
		onboarding: function (state) {
			return state.onboarding;
		},
		assetUrl: function (state) {
			return state.assetUrl;
		},
		baseUrl: function (state) {
			return state.baseUrl;
		},
	},
	mutations: {
		SET_LOADED: (loaded) => {
			state.loaded = loaded;
		},
		SET_INIT: (state, condition) => {
			state.init = condition;
		},
		SET_ONBOARDING: function (state, condition) {
			state.onboarding = condition;
		},
	},
	actions: {
		async init(store, config = {}) {
			let env = config.env || process.env.NODE_ENV;
			let mode = config.mode || null;

			if (env === 'production') {
				store.state.assetUrl = 'https://file.swipekit.app';
			}

			if (typeof window !== 'undefined') {
				await store.dispatch('getEnvironment', {
					env: env,
					mode: mode,
				});
			} else {
				let url = 'http://localhost:4000';

				if (env === 'production') {
					url = 'https://api.swipekit.app';
				}
				store.state.baseUrl = url;
			}

			await api.init(store.state.mode);

			await store.dispatch('getSessionId');

			http.setContext(store);
			http.setUrl(store.state.baseUrl);

			let token = await api.users.getToken(mode);

			if (token) {
				http.setHeader('Authorization', `Bearer ${token}`);
			}
		},

		set: function (store, payload) {
			if (payload.key && payload.value) {
				store.state[payload.key] = payload.value;
			}
		},

		async processEnvironment(store, config = {}) {
			let mode = config.mode || null;
			let data = {
				version: process.env.VUE_APP_VERSION || store.state.version,
				browser: '',
				mode: mode || store.state.mode,
				baseUrl: store.state.baseUrl || '',
				screenX: 1400,
				screenY: 900,
				os: '',
			};

			if (typeof window === 'undefined') {
				return data;
			}

			let isIE = /*@cc_on!@*/ false || !!document.documentMode;
			let isEdge = (!isIE && !!window.StyleMedia) || '';
			if (!mode) {
				mode = 'WEBAPP';

				if (typeof chrome !== 'undefined' && chrome.permissions) {
					mode = 'EXTENSION';
					window.mode = 'EXTENSION';
					//window.browser = require("webextension-polyfill");
				} else {
					window.mode = 'WEBAPP';
				}
			}
			let screenX = window.screen.availWidth || '';
			let screenY = window.screen.availHeight || '';
			let browserName = '';
			let url = '';

			if (navigator.userAgent.indexOf('Chrome') != -1 && !isEdge) {
				browserName = 'chrome';
			} else if (navigator.userAgent.indexOf('Safari') != -1 && !isEdge) {
				browserName = 'safari';
			} else if (navigator.userAgent.indexOf('Firefox') != -1) {
				browserName = 'firefox';
			} else if (navigator.userAgent.indexOf('MSIE') != -1 || !!document.documentMode == true) {
				//IF IE > 10
				browserName = 'ie';
			} else if (isEdge) {
				browserName = 'edge';
			} else {
				browserName = 'other-browser';
			}

			let os = await store.dispatch('getOS');

			data.browser = browserName;
			data.mode = mode;
			data.baseUrl = url;
			data.screenX = screenX;
			data.screenY = screenY;
			data.os = os;

			return data;
		},

		async getEnvironment(store, config = {}) {
			let env = config.env || null;

			let data = await store.dispatch('processEnvironment', config);

			data.baseUrl = 'http://localhost:4000';

			if (env === 'production') {
				data.baseUrl = 'https://api.swipekit.app';
			}

			let date = new Date();

			let utcOffset = date.getTimezoneOffset() / 60;

			store.state.version = data.version;
			store.state.browser = data.browserName;
			store.state.mode = data.mode;
			store.state.baseUrl = data.baseUrl;
			store.state.screenX = data.screenX;
			store.state.screenY = data.screenY;
			store.state.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			store.state.utcOffset = utcOffset;
			store.state.os = data.os;
		},

		async getOS() {
			var OSName = 'Unknown';
			if (window.navigator.userAgent.indexOf('Windows NT 10.0') != -1) OSName = 'Windows 10';
			if (window.navigator.userAgent.indexOf('Windows NT 6.3') != -1) OSName = 'Windows 8.1';
			if (window.navigator.userAgent.indexOf('Windows NT 6.2') != -1) OSName = 'Windows 8';
			if (window.navigator.userAgent.indexOf('Windows NT 6.1') != -1) OSName = 'Windows 7';
			if (window.navigator.userAgent.indexOf('Windows NT 6.0') != -1) OSName = 'Windows Vista';
			if (window.navigator.userAgent.indexOf('Windows NT 5.1') != -1) OSName = 'Windows XP';
			if (window.navigator.userAgent.indexOf('Windows NT 5.0') != -1) OSName = 'Windows 2000';
			if (window.navigator.userAgent.indexOf('Mac') != -1) OSName = 'Mac/iOS';
			if (window.navigator.userAgent.indexOf('X11') != -1) OSName = 'UNIX';
			if (window.navigator.userAgent.indexOf('Linux') != -1) OSName = 'Linux';

			return OSName;
		},

		async setLoaded() {},

		async getSessionId(store) {
			let sessionId = await api.app.getSessionId();
			if (!sessionId) {
				sessionId = uuidv4();
			}
			await api.app.setSessionId(sessionId);
			store.state.sessionId = sessionId;
		},

		async hasInit(store) {
			store.commit('SET_INIT', true);
		},

		async hasOnboarded(store) {
			store.commit('SET_ONBOARDING', true);
		},
	},
};

export default store;
