<template>
	<div class="c-block-view">
		<!-- right-panel -->
		<div class="left-panel" v-if="ad">
			<!-- <div class="left-panel__viewer" :style="computedViewerStyle">
        <Renderer :block="ad.block"></Renderer>
      </div> -->
			<Assets :block="ad.block"></Assets>
		</div>

		<!-- left-panel -->
		<div :class="['right-panel', { 'no-overflow': tab === 'settings' }]" v-if="ad">
			<!-- Header -->
			<div class="right-panel__header" v-if="!standalone">
				<Avatar :block="ad.block" :showSecondary="false"></Avatar>
				<article>
					<span>{{ text }}</span>
					<span class="subtitle">Saved on {{ createdAt }}&nbsp;•&nbsp;{{ computedAdBlockType }}&nbsp;•&nbsp;{{ ad.block.assetType }}</span>
				</article>
			</div>

			<!-- Tabs -->
			<!-- <div class="right-panel__tabs" v-if="!standalone">
        <a
          :class="[{ active: tab === tabIn.value }]"
          href="#"
          v-for="tabIn in tabs"
          :key="tabIn.value"
          @click.prevent="onTabClick(tabIn)"
        >
          {{ tabIn.key }}
          <label v-if="tabIn.value === 'comments'">
            {{ commentCount }}
          </label>
        </a>
      </div> -->

			<!-- Body -->
			<div class="right-panel__body">
				<!-- Metadata -->
				<template v-if="tab === 'metadata'">
					<!-- <Section v-if="ad.block.type === 'facebook'" title="Ad start date">
            <ItemDate :block="ad.block"></ItemDate>
          </Section> -->
					<!-- <FormBlockUpdateAdmin v-if="user && user.type === 'ADMIN'" :ad="ad"></FormBlockUpdateAdmin> -->
					<Labels :block="ad.block"></Labels>
					<Section v-if="ad.block.meta && ad.block.meta.cta" title="CTA text" :text="ad.block.meta.cta">
						<p class="article-text-sm">
							{{ ad.block.meta.cta }}
						</p>
					</Section>
					<Section v-if="ad.block.meta.ctaLink" title="CTA link" :text="ad.block.meta.cta">
						<Copy :text="ad.block.meta.ctaLink"></Copy>
					</Section>
					<Section v-if="sourceUrl && ad.block.type !== 'facebook'" title="Source">
						<a class="btn btn-primary btn-icon btn-sm" :href="sourceUrl" target="_blank">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM10.25 8C10.25 8.41421 10.5858 8.75 11 8.75H14.1893L7.46967 15.4697C7.17678 15.7626 7.17678 16.2374 7.46967 16.5303C7.76256 16.8232 8.23744 16.8232 8.53033 16.5303L15.25 9.81066V13.0769C15.25 13.4911 15.5858 13.8269 16 13.8269C16.4142 13.8269 16.75 13.4911 16.75 13.0769V8C16.75 7.58579 16.4142 7.25 16 7.25H11C10.5858 7.25 10.25 7.58579 10.25 8Z"
									fill="currentColor"
								/>
							</svg>
							Visit source
						</a>
					</Section>
					<Section v-if="adTitle" title="Ad Title" :text="adTitle">
						<p class="article-text-sm">{{ adTitle }}</p>
					</Section>
					<Section v-if="adCopy" title="Ad Copy" :text="adCopy">
						<p class="article-text-sm ad-copy" v-html="adCopy"></p>
					</Section>
					<Section v-if="ad.block.categories && ad.block.categories.length > 0" title="Categories" description="Categories are scraped from the Ad" :text="ad.block.categories.join(',')">
						<Categories :block="ad.block"></Categories>
					</Section>

					<Section v-if="ad.block.transcript && ad.block.transcript.text" :text="ad.block.transcript.text.join('')" title="Ad Transcript">
						<Transcript @event="onEvent" :transcript="ad.block.transcript"></Transcript>
					</Section>

					<!-- <Section v-if="!ad.block.transcript && ad.block.assets[0].type === 'video'" title="Ad Transcript">
            <button class="btn btn-primary btn-box">
              <span class="c-spinner"></span>
              <span> Generate video Transcript </span>
            </button>
            <FormGenerateTranscript @onSubmit="onGeneratedTranscript" :blockId="ad.block.id"></FormGenerateTranscript>
          </Section> -->

					<div class="c-block-view__divider"></div>

					<Section title="Saved by" v-if="savedBy">
						<p class="article-text-sm">
							{{ savedBy.name }}
						</p>
					</Section>
					<Section v-if="ad.block.metaId && ad.block.type === 'facebook'" title="Ad ID">
						<code class="article-text-sm">{{ ad.block.metaId }}</code>
					</Section>
					<!-- <Section v-if="ad.block.type === 'facebook'" title="Ad Library Link">
            <UtilAdLink :block="ad.block"></UtilAdLink>
          </Section> -->

					<Links :block="ad.block"></Links>

					<div class="c-block-view__divider"></div>

					<FormBlockUpdate v-if="forceRender" :form="ad"></FormBlockUpdate>

					<FormBlockDelete v-if="forceRender" :form="ad" @onSubmit="afterDelete"></FormBlockDelete>

					<!-- <div class="c-block-view__divider"></div>

          <UtilHistory :block="ad.block"></UtilHistory> -->

					<div class="c-block-view__divider"></div>

					<UtilLinkExplainer :ad="ad"></UtilLinkExplainer>
				</template>

				<!-- Settings -->
				<template v-if="tab === 'settings'">
					<div :class="['c-block-view__form', { disabled: isTrialOver }]" v-if="!standalone">
						<!-- <h4>Settings</h4> -->
						<FormBlockUpdate v-if="forceRender" :form="ad"></FormBlockUpdate>
						<div class="c-block-view__divider"></div>
						<FormBlockDelete v-if="forceRender" :form="ad" @onSubmit="afterDelete"></FormBlockDelete>
					</div>
				</template>

				<!-- Transcript -->
				<template v-if="tab === 'transcripts'">
					<button class="btn btn-primary">Generate AI Transcripts</button>
				</template>

				<div class="c-block-view__nag" v-if="isTrialOver">
					<a href="#" @click.prevent="onUpgrade" class="btn btn-primary" to="/profile">Upgrade to edit</a>
				</div>
			</div>
		</div>

		<!-- footer-->
		<!--
    <div class="c-block-view__footer">
      <a class="btn btn-primary btn-icon" @click="navigate('prev')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
        Previous
      </a>
      <a class="btn btn-primary btn-icon-right" @click="navigate('next')">
        Next

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
      </a>
    </div>
    -->
	</div>
</template>

<script>
import Modal from './index.vue';
import Avatar from '@swipekit/components/utils/avatar';
import Accordion from '@swipekit/components/app/accordion';

import InputBoards from '@swipekit/components/form/input-boards';

import InputTags from '@swipekit/components/form/input-tags';

import renderDate from '@swipekit/lib/render-date';

import FormBlockUpdate from '@swipekit/components/form/form-block-update';
import FormBlockDelete from '@swipekit/components/form/form-block-delete';
import FormBlockUpdateAdmin from '@swipekit/components/form/form-block-update-admin';
import FormGenerateTranscript from '@swipekit/components/form/form-generate-transcript';

import Renderer from '@swipekit/components/renderer';

import Cta from '@swipekit/components/utils/cta';

import ItemDate from '@swipekit/components/item/date';

import Comment from '@swipekit/components/comment';

import Section from './section';

import Categories from '@swipekit/components/utils/categories';

import Copy from '@swipekit/components/utils/copy';

import UtilAdLink from '@swipekit/components/utils/ad-link';
import UtilLinkExplainer from '@swipekit/components/utils/link-explainer';
import UtilHistory from '@swipekit/components/utils/history';

import Assets from './assets';
import Labels from './labels';
import Links from './links';
import Transcript from './transcript';

import api from '@swipekit/lib/api.js';

export default {
	components: {
		Modal,
		Avatar,
		Accordion,
		InputBoards,
		InputTags,
		FormBlockUpdate,
		FormBlockDelete,
		FormBlockUpdateAdmin,
		FormGenerateTranscript,
		Renderer,
		Cta,
		ItemDate,
		Comment,
		Section,
		Categories,
		Copy,
		UtilAdLink,
		UtilLinkExplainer,
		UtilHistory,
		Assets,
		Labels,
		Links,
		Transcript,
	},

	data: function () {
		return {
			currentBlock: null,
			processing: false,
			forceRender: true,
			tab: 'metadata',
			tabs: [
				{
					key: 'Metadata',
					value: 'metadata',
				},
				{
					key: 'Settings',
					value: 'settings',
				},
				// {
				//   key: "Transcripts",
				//   value: "transcripts",
				// },
			],
		};
	},

	watch: {
		'options.active': {
			handler: function () {},
		},
		block: function () {
			this.forceRender = false;
			this.$nextTick(() => {
				this.forceRender = true;
			});
		},
	},

	props: {
		latestAd: {},
	},

	computed: {
		ad: function () {
			if (!this.latestAd) {
				return;
			}
			let ad = JSON.parse(JSON.stringify(this.latestAd));

			if (this.currentBlock) {
				ad.block = this.currentBlock;
			}

			return ad;
		},
		transcriptGenerationText: function () {
			return `Generate Transcript?`;
		},
		computedViewerStyle: function () {
			let assetRatio = 1;
			if (this.ad.block && this.ad.block.assetRatio) {
				assetRatio = this.ad.block.assetRatio;
			}

			let padding = assetRatio * 100;
			let str = `padding-bottom:${padding}%;`;

			return str;
		},
		user: function () {
			return this.$store.getters['users/user'];
		},
		commentCount: function () {
			return this.$store.getters['comments/byAd'](this.ad.id).length;
		},
		computedAdBlockType: function () {
			let type = this.ad.block.type;
			type = type.charAt(0).toUpperCase() + type.slice(1);

			return type;
		},
		isTrialOver: function () {
			return this.$store.getters['users/isTrialOver'];
		},
		adCopy: function () {
			let adCopy = this.ad.block.adCopy;

			if (!adCopy) {
				return null;
			}

			if (adCopy.includes('&#') && adCopy.length < 200) {
				return null;
			}

			return adCopy;
		},
		adTitle: function () {
			return this.ad.block.adTitle;
		},
		// viewCache: function () {
		//   return this.$store.getters["ads/viewCache"];
		// },
		sourceUrl: function () {
			let block = this.ad.block;

			if (block.source && typeof block.source === 'string') {
				return block.source;
			}

			return null;
		},
		createdAt: function () {
			let createdAt = this.ad.block.createdAt;

			let str = renderDate(createdAt);

			return str;
		},
		savedBy: function () {
			if (this.standalone) {
				return null;
			}

			let ad = this.ad;
			let userId = ad.userId;

			if (!userId) {
				return null;
			}

			let workspace = this.$store.getters['workspaces/all'];

			let user = workspace.users.filter((user) => {
				if (user.id === userId) {
					return true;
				}
				return false;
			});

			if (user && user.length > 0) {
				return user[0];
			}

			return null;
		},
		text: function () {
			if (!this.ad.block) {
				console.log(this.ad);
			}
			return this.ad.block.title;
			let type = this.block.type;

			if (type === 'email') {
				return 'Email';
			}
			if (type === 'webpage') {
				return 'Webpage';
			}
			if (type === 'webpageSelection') {
				return 'Webpage section';
			}
			if (type === 'facebook') {
				return 'Facebook Ad library';
			}

			return '';
		},
		standalone: function () {
			return this.$store.getters['standalone'];
		},
	},

	methods: {
		onGeneratedTranscript: function (block) {
			console.log(block);
			this.currentBlock = block;
		},
		onEvent: function (payload) {
			payload.blockId = this.ad.block.id;

			api.api.track(payload);
		},
		onTabClick: function (tab) {
			this.tab = tab.value;
		},
		onSubmit: function () {
			this.$emit('onSubmit');
		},
		async afterDelete() {
			this.$emit('afterDelete');
		},
		onUpgrade: function () {
			this.$router.push('/profile');
			this.$store.dispatch('ads/setView', null);
		},
	},

	mounted: function () {
		//this.options.active = true;
	},
};
</script>

<style lang="scss">
.c-block-view {
	width: 100%;
	height: 100%;
	display: flex;

	.left-panel {
		position: relative;
		width: 50%;
		height: 100%;
		flex-grow: 1;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		overflow: hidden;
		overflow-y: auto;

		background-color: var(--color-bg-2);

		&__viewer {
			display: flex;
			justify-content: center;
			align-items: stretch;

			overflow: hidden;
			overflow-y: auto;

			position: relative;
			//padding: var(--spacer);
			//overflow: scroll;
			text-align: center;
		}

		> .btn {
			position: absolute;
			bottom: var(--spacer-sm);
			right: var(--spacer-sm);
		}

		.media-wrapper {
			max-height: 100%;
			display: inline-block;
			box-shadow: var(--box-shadow-light);

			//overflow-y: auto;

			img {
				display: block;
				max-width: 100%;
			}
		}

		.c-renderer {
			width: 100%;
			height: 100%;

			> div {
				height: 100%;
			}
		}

		.r-facebook .VueCarousel .VueCarousel-navigation button {
			width: var(--spacer);
			height: var(--spacer);
			background-color: var(--color-bg-4);
			border-radius: var(--border-radius-full);
		}

		.c-renderer {
			video {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	.right-panel {
		display: flex;
		flex-direction: column;
		width: 50%;
		height: 100%;
		border-left: hsl(var(--s), 20%, 80%) solid 1px;
		overflow-y: auto;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;

		background-color: var(--color-bg-1);

		.c-input-tags {
			.vue-tags-input .ti-tag {
				padding: var(--margin-sm) var(--margin-lg);
				padding-right: var(--margin-lg);
			}
			.vue-tags-input .ti-input {
				min-height: auto;
			}

			.vue-tags-input .ti-tags .ti-new-tag-input-wrapper input {
				border-radius: 4px;
			}
		}

		.c-input-boards-dropdown {
			&__input {
				min-height: 44px;
			}
		}

		&.no-overflow {
			overflow-y: initial;
		}

		.c-item-date {
			padding-top: 0;
		}

		.c-comment {
			margin-left: calc(-1 * var(--margin-lg));
			width: calc(100% + var(--margin-lg) * 2);
		}

		.ad-copy {
			white-space: break-spaces;
		}

		&__header {
			padding: var(--spacer-sm);
			display: flex;
			align-items: center;
			border-bottom: var(--color-bg-5) solid 1px;

			> article {
				display: flex;
				flex-direction: column;
				margin-left: var(--spacer-sm);
				padding-right: var(--spacer-sm);

				.subtitle {
					font-size: var(--font-size-sm);
					opacity: 0.75;
				}
			}
		}

		&__tabs {
			display: flex;
			width: 100%;

			a {
				flex-grow: 1;
				display: inline-flex;
				justify-content: center;
				padding: var(--margin-lg) var(--spacer-sm);
				padding-bottom: var(--margin);
				text-align: center;
				border-bottom: var(--color-bg-5) solid 2px;
				color: var(--color-font);
				text-decoration: none !important;

				&:hover,
				&.active {
					background-color: hsla(var(--p), 75%, 75%, 0.125);
					color: var(--color-primary);
					text-decoration: none !important;
					border-color: currentColor;
				}

				&.active {
					font-weight: 500;
				}

				label {
					display: inline-block;
					min-width: 24px;
					margin-left: var(--margin);
					padding: var(--margin-sm) var(--margin);
					font-size: var(--font-size-xs);
					font-weight: 500;
					border: var(--color-bg-5) solid 1px;
					border-radius: var(--border-radius-full);
				}
			}
		}

		.c-form {
			margin: var(--margin-lg) 0;
		}

		.c-accordion {
			img {
				max-width: 100% !important;
			}
		}

		&__body {
			padding: var(--spacer-sm);

			> article {
				margin: var(--margin-lg) 0;
				white-space: pre-wrap;
			}

			.article-text-sm {
				margin-bottom: 0;
			}

			code {
				font-size: var(--font-size-xs);
			}

			h6 {
				margin-top: var(--margin-lg);
			}
		}

		.c-cta {
		}

		&__buttons {
			margin-top: auto;
			width: 100%;
			border-top: var(--color-bg-5) solid 1px;

			.btn {
				position: relative;
				max-height: initial;
				padding: var(--spacer-sm);
				width: 50%;
				border-radius: 0;

				&:hover,
				&:active,
				&:focus {
					z-index: 1;
				}

				&:first-child {
					border-right: var(--color-bg-5) solid 1px !important;
				}
			}
		}
	}

	&__footer {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 64px;
		border-top: var(--color-bg-5) solid 1px;
		background-color: var(--color-bg-2);
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;

		padding: 16px;
		display: flex;
		justify-content: space-between;
	}

	&__divider {
		width: 100%;
		height: 1px;
		background-color: var(--color-bg-5);
		margin: var(--spacer-sm) 0;
	}

	&__form {
		&.disabled {
			cursor: not-allowed;
			opacity: 0.8;
			pointer-events: none;
		}
	}

	&__nag {
		border: hsla(var(--s), 60%, 60%, 0.15) solid 1px;
		border-radius: var(--border-radius);
		padding: var(--spacer-sm);
		background-color: hsla(var(--s), 60%, 60%, 0.15);
	}

	@media screen and (max-width: 600px) {
		display: block;
		overflow-y: auto;

		.left-panel {
			width: 100%;
			height: auto;
		}
		.right-panel {
			width: 100%;
			height: auto;
		}

		&__footer {
			display: none;
		}
	}
}
</style>
