<template>
	<div :class="['c-brands-insight', { processing: processing === true }]" :data-brandid="brand.id">
		<section>
			<header>
				<img :src="`${assetUrl}/${brand.logo}`" />
				<article>
					<h3>{{ brand.name }}</h3>

					<p>
						<span v-if="currentInsight">{{ brand.category }} | Total ads: {{ lifetimeInsight.totalAds }}, Active ads: {{ lifetimeInsight.activeAds }}</span>
					</p>
				</article>
			</header>
			<div class="c-brands-insight__control">
				<a v-if="fbadlibrarylink" :href="fbadlibrarylink" target="_blank" class="btn btn-icon btn-box btn-sm">
					<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M13 4H10C7.19974 4 5.79961 4 4.73005 4.54497C3.78924 5.02433 3.02433 5.78924 2.54497 6.73005C2 7.79961 2 9.19974 2 12V14C2 16.8003 2 18.2004 2.54497 19.27C3.02433 20.2108 3.78924 20.9757 4.73005 21.455C5.79961 22 7.19974 22 10 22H12C14.8003 22 16.2004 22 17.27 21.455C18.2108 20.9757 18.9757 20.2108 19.455 19.27C20 18.2004 20 16.8003 20 14V11M12 12L22 2M22 2H17M22 2V7"
							stroke="currentColor"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						></path>
					</svg>
					<span> Facebook ad library page </span>
				</a>
				<InputButtonChoice buttonClass="btn btn-sm btn-box" label="Remove tracker" @input="removeTracker"></InputButtonChoice>
			</div>
		</section>
		<section>
			<h3>Brand statistics</h3>
			<template v-if="brand.tracker.status === 'ACTIVE'">
				<div class="c-brands-insight__duration">
					<span @click="setDuration(0)" :class="[{ active: days === 0 }]"> Lifetime </span>
					<span @click="setDuration(7)" :class="[{ active: days === 7 }]"> 7 Days </span>
					<span @click="setDuration(30)" :class="[{ active: days === 30 }]"> 30 Days </span>
				</div>
				<template v-if="currentInsight">
					<Stats :insight="currentInsight"></Stats>
					<Breakdown :insight="currentInsight"></Breakdown>
					<Timeline :insight="currentInsight" :days="days"></Timeline>
					<Links :insight="currentInsight"></Links>
					<Words :insight="currentInsight"></Words>
					<Networks :insight="currentInsight"></Networks>
				</template>
				<blockquote v-else>
					<p>Insights haven't been calculated for this brand yet. They will be calculated automatically when the tracker scrapes a few ads.</p>
				</blockquote>
			</template>
			<template v-else>
				<blockquote>
					<p>This tracker is inactive.</p>
					<p>Trackers become inactive if your account has been deactivated or you have reached over the limit of your allowed trackers.</p>
				</blockquote>
			</template>
		</section>
	</div>
</template>

<script>
import Stats from './stats';
import Breakdown from './breakdown';
import Links from './links';
import Words from './words';
import Networks from './networks';
import Timeline from './timeline';

import InputButtonChoice from '@swipekit/components/form/input-button-choice';

export default {
	components: {
		Stats,
		Breakdown,
		Links,
		Words,
		Networks,
		Timeline,
		InputButtonChoice,
	},

	props: {
		brand: {},
	},

	data: function () {
		return {
			days: 0,
			processing: false,
		};
	},

	computed: {
		fbadlibrarylink: function () {
			return `https://www.facebook.com/ads/library?active_status=active&ad_type=all&country=ALL&media_type=all&search_type=page&view_all_page_id=${this.brand.fbId}`;
		},
		lifetimeInsight: function () {
			if (!this.brand.insight) {
				return;
			}
			return this.brand.insight.lifetime;
		},
		currentInsight: function () {
			if (!this.brand.insight) {
				return;
			}
			if (!this.days) {
				return this.brand.insight.lifetime;
			}
			if (this.days === 7) {
				return this.brand.insight['7days'];
			}
			if (this.days === 30) {
				return this.brand.insight['30days'];
			}
		},
		assetUrl: function () {
			return this.$store.getters['config/assetUrl'];
		},
	},

	methods: {
		async removeTracker() {
			this.processing = true;
			this.$store.dispatch('message/onTrackerRemoved');
			await this.$store.dispatch('brands/removeTracker', this.brand.tracker.id);

			window.location.href = `/brands`;
		},
		setDuration: function (days) {
			if (!days) {
				this.days = 0;
			} else {
				this.days = days;
			}
		},
	},
};
</script>

<style lang="scss">
.c-brands-insight {
	position: relative;
	background-color: var(--color-bg-1);
	width: calc(var(--header-width) + 360px);
	margin: var(--spacer-sm) auto;

	box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(9, 9, 11, 0.08) 0px 0px 0px 1px, rgba(9, 9, 11, 0.08) 0px 1px 2px -1px, rgba(9, 9, 11, 0.04) 0px 2px 4px 0px;
	border-radius: 20px;

	&.processing {
		opacity: 0.75;
		pointer-events: none;
	}

	&__buttons {
		position: absolute;
		top: 14px;
		right: var(--margin-lg);
		display: flex;
		flex-direction: column;

		a {
			&:last-child {
				margin-top: var(--margin);
			}
		}
	}

	&__duration {
		display: inline-block;
		border: rgba(9, 9, 11, 0.1) solid 1px;
		border-radius: 8px;
		padding: 4px;

		span {
			display: inline-block;
			padding: var(--margin) var(--margin-lg);
			border-radius: 4px;
			font-size: var(--font-size-sm);
			line-height: 1;
			user-select: none;
			cursor: pointer;
			font-feature-settings: 'case' 0, 'dlig' 0, 'frac' 0, 'dnom' 0, 'numr' 0, 'subs' 0, 'sups' 0, 'tnum', 'zero' 0, 'ss01', 'ss02' 0, 'ss03' 0, 'ss04', 'ss05' 0, 'ss06' 0, 'ss07' 0, 'ss08' 0,
				'cv01' 0, 'cv02' 0, 'cv03' 0, 'cv04' 0, 'cv05' 0, 'cv06' 0, 'cv07' 0, 'cv08' 0, 'cv09' 0, 'cv10' 0, 'cv11' 0, 'cv12' 0, 'cv13' 0, 'cpsp' 0, 'c2sc' 0, 'salt' 0, 'aalt' 0, 'calt', 'ccmp', 'locl',
				'kern';

			&.active {
				background-color: var(--color-primary);
				color: #fff;
			}

			&:hover {
				&:not(.active) {
					background-color: var(--color-primary-light);
				}
			}
		}
	}

	&__control {
		padding-top: var(--margin-lg);
		display: flex;

		a {
			height: 32px;
			margin-right: var(--margin);
		}

		.c-input-button-choice {
			display: inline-block;

			.btn {
				min-height: 32px;
			}
		}
	}

	section {
		padding: var(--spacer-sm);

		&:not(:last-child) {
			border-bottom: rgba(9, 9, 11, 0.1) solid 1px;
		}
	}

	header {
		display: grid;
		grid-template-columns: 64px 1fr;
		grid-column-gap: var(--spacer-sm);
		align-items: center;

		img {
			width: 64px;
			height: 64px;
			background-color: cyan;
			border-radius: 100%;
			overflow: hidden;
			object-fit: cover;
		}

		h3 {
			display: inline-block;
		}

		p {
			margin: 0;
			display: flex;
			align-items: center;

			a {
				margin-left: var(--margin);
			}
		}
	}

	blockquote {
		padding: var(--spacer-sm);
		background-color: var(--color-bg-3);
		border-radius: var(--border-radius);

		p:last-of-type {
			margin-bottom: 0;
		}
	}

	h3 {
		font-family: var(--font-family);
	}
}
</style>
