<template>
	<div class="c-billing-faqs">
		<Accordion>
			<template v-slot:title>
				<h4>Why can’t I pay for Swipekit?</h4>
			</template>

			<template v-slot:body>
				<div>
					<p>Sometimes, payments fail even if the card is valid. While this is largely out of our control and in Stripe's hands, there are a few things you can do about this:</p>
					<ul>
						<li>Make sure you aren't using a VPN. For best results, connect to your local wifi and pay via the Chrome browser.</li>
						<li>American express gets declined a lot. If this happens, use another card.</li>
					</ul>
					<p>If your card is getting declined consistently, contact customer support and we will help you sort it out.</p>
				</div>
			</template>
		</Accordion>

		<Accordion>
			<template v-slot:title>
				<h4>Why did my monthly payment fail?</h4>
			</template>

			<template v-slot:body>
				<div>
					<p>At the end of your billing cycle, Swipekit will try to bill your card.</p>
					<p>If your card gets declined, Swipekit will try multiple attempts over the next few days.</p>
					<p>If your card still isn't charged, Swipekit will deactivate your account and you will need to re-enter your card details in order to use Swipekit.</p>
					<p>Don't worry, we won't delete your data when your account is deactivated.</p>
				</div>
			</template>
		</Accordion>

		<Accordion>
			<template v-slot:title>
				<h4>If my payment fails, will my data be lost?</h4>
			</template>

			<template v-slot:body>
				<div>
					<p>No, your data isn't lost.</p>
					<p>We keep your data around for at least 3 months - unless you explicitely delete your workspace.</p>
				</div>
			</template>
		</Accordion>
	</div>
</template>

<script>
import Accordion from '@swipekit/components/utils/accordion';

export default {
	components: {
		Accordion,
	},
};
</script>

<style lang="scss">
.c-billing-faqs {
	h4 {
		font-weight: 450;
		text-transform: initial;
		letter-spacing: 0;
	}
}
</style>
