<template>
	<div class="c-brands-list">
		<!-- <SearchBar :brands="true" :activeCategories="multiplayerCategories" v-model="params" @search="onSearch" @view="onView"></SearchBar> -->
		<div>
			<Item v-for="block in computedBlocks" :multiplayer="true" :item="block" :key="block.id"></Item>
		</div>
		<Empty v-if="computedBlocks.length === 0 && !processing" />
		<BrandFaqs></BrandFaqs>
	</div>
</template>

<script>
import SearchBar from '@swipekit/components/utils/search-bar';
import Item from './item';

import filter from '@swipekit/lib/filter';
import { getParams, setParams } from '@swipekit/lib/query-param-utils';

import api from '@swipekit/lib/api';

import Empty from './empty';

import BrandFaqs from './brand-faqs.vue';

export default {
	components: {
		SearchBar,
		Item,
		Empty,
		BrandFaqs,
	},

	data: function () {
		return {
			params: {
				query: '',
				network: 'all',
				mediaType: 'all',
				activeAds: 0,
				categories: [],
				brands: [],
			},
			blocks: [],
			skip: 0,
			take: 0,

			end: false,

			view: '',
		};
	},

	props: {},

	watch: {
		$route: function () {},
		refreshFilters: function () {
			this.filter();
		},
	},

	computed: {
		multiplayerCategories: function () {
			return this.$store.getters['app/multiplayerCategories'];
		},
		refreshFilters: function () {
			return this.$store.getters['app/refreshFilters'];
		},
		computedBlocks: function () {
			let blocks = this.blocks;

			return blocks;
		},
		processing: function () {
			return this.$store.getters['app/processing'];
		},
	},

	methods: {
		async onView(view) {
			this.view = view;

			api.api.setOption('view', view);
		},
		onSubmit: function (e) {
			this.$emit('onSubmit', e);
		},
		applyParams: function (params) {
			if (params.query) {
				this.params.query = params.query;
			}

			if (params.skip) {
				this.skip = params.skip;
			}
			if (params.take) {
				this.take = params.take;
			}

			if (params.categories) {
				this.params.categories = params.categories;
			}

			if (params.brands) {
				this.params.brands = params.brands;
			}

			if (params.mediaType) {
				this.params.mediaType = params.mediaType;
			}

			if (params.activeAds) {
				this.params.activeAds = params.activeAds;
			}

			if (params.network) {
				this.params.network = params.network;
			}
		},
		onEnd: function () {
			if (this.processing) {
				return;
			}
			if (this.end) {
				return;
			}
			this.skip += this.take;
			this.filter();
		},
		async onSearch(params) {
			this.blocks = [];
			this.skip = 0;
			this.end = false;
			await this.filter(params);
		},
		async filter(params, firstTime = false) {
			if (this.processing) {
				return;
			}
			this.$store.dispatch('app/setProcessing', true);

			if (firstTime) {
				params = getParams();

				// if first time, force 1st page
				params.skip = 0;

				this.applyParams(params);
			}
			if (!params) {
				params = getParams();
			}

			params.skip = this.skip;
			params.take = this.take;

			setParams(params);

			const blocks = await filter(params, 'filter-brands');

			if (blocks.length < this.take) {
				this.end = true;
			}
			this.blocks = this.blocks.concat(blocks);

			this.$nextTick(() => {
				this.$store.dispatch('app/setProcessing', false);
			});
		},
	},

	mounted: async function () {
		this.skip = 0;
		this.take = 40;
		this.filter(null, true);

		let options = await api.api.getOptions();

		this.view = options.view || '';
	},
};
</script>

<style lang="scss">
.c-brands-list {
	padding-top: var(--spacer);
	padding-bottom: var(--spacer);
}
</style>
