const serializeObject = function (query) {
	var queryString = Object.keys(query)
		.map((key) => {
			if (typeof query[key] === null) {
				return '';
			}
			if (typeof query[key] === undefined) {
				return '';
			}
			if (typeof query[key] === 'number' && query[key] === 0) {
				return key + '=' + query[key] + '&';
			}
			if (query[key].constructor === Array) {
				var theArrSerialized = '';
				for (let singleArrIndex of query[key]) {
					theArrSerialized = theArrSerialized + key + '[]=' + singleArrIndex + '&';
				}
				return theArrSerialized;
			} else {
				return key + '=' + query[key] + '&';
			}
		})
		.join('');

	return queryString;
};

export const verifyParams = function (params) {
	let platforms = ['all', 'facebook', 'instagram', 'tiktok', 'tiktok-top-ads', 'tiktok-ad-library', 'linkedin', 'google', 'pinterest'];

	let platform = params.platform || 'all';

	if (!platforms.includes(platform)) {
		platform = 'all';
	}

	let networkTypes = ['facebook', 'tiktok', 'tiktok-top-ads', 'tiktok-ad-library', 'instagram', 'linkedin', 'google', 'pinterest'];
	let mediaTypes = ['all', 'video', 'image', 'carousel', 'text', 'youtube'];
	let activeAdTypes = [0, 1, 2];
	let sortTypes = ['all', 'adReachFirst', 'activeDaysFirst', 'createdAtFirst', 'createdAtLast'];

	if (!params.sort) {
		params.sort = 'all';
	}
	if (params.sort) {
		if (!sortTypes.includes(params.sort)) {
			params.sort = 'all';
		}
	}

	if (!params.mediaType) {
		params.mediaType = 'all';
	}

	if (!mediaTypes.includes(params.mediaType)) {
		params.mediaType = 'all';
	}

	if (!params.networks) {
		params.networks = [];
	}

	if (params.networks.length > 0) {
		params.networks = params.networks.filter((network) => {
			if (networkTypes.includes(network)) {
				return true;
			}
			return false;
		});
	}

	if (!params.brands) {
		params.brands = [];
	}

	if (params.brands) {
		params.brands = params.brands.map((brand) => {
			if (typeof brand === 'number') {
				return brand;
			} else if (typeof brand === 'object' && brand.id) {
				return brand.id;
			}

			let temp = parseInt(brand);

			if (!isNaN(temp)) {
				return brand;
			}

			return -1;
		});

		params.brands = params.brands.filter((brand) => {
			if (brand === -1) {
				return false;
			}
			return true;
		});
	}

	if (!params.activeAds) {
		params.activeAds = 0;
	}
	if (typeof params.activeAds !== 'number') {
		params.activeAds = 0;
	}
	if (!activeAdTypes.includes(params.activeAds)) {
		params.activeAds = 0;
	}

	if (params.tag) {
	}

	let obj = {
		/**
		 * Offset, how many blocks to offset filter by
		 */
		skip: parseInt(params.skip || 0),

		/**
		 * How many blocks to retrive
		 */
		take: parseInt(params.take || 40),

		/**
		 * Freetext search query
		 */
		query: params.query || '',

		/**
		 * Categories. Multiplayer and facebook only for now. We'll expand it in the future
		 */
		categories: params.categories || [],

		/**
		 * Tag. User entered tags, only for library and boards
		 */
		tag: params.tag || '',

		/**
		 *
		 */
		activeAds: params.activeAds,

		/**
		 * Array of networks/platforms, eg facebook, linkedin, instagram, etc
		 */
		networks: params.networks,

		/**
		 * Get Brands by their ids
		 */
		brands: params.brands,

		/**
		 * What mediaType would the user like to see?
		 */
		mediaType: params.mediaType,

		/**
		 * Sorting types
		 */
		sort: params.sort,
	};

	return obj;
};

export const setParams = function (params) {
	params = verifyParams(params);

	let url = new URL(window.location.href);

	let path = url.pathname;

	params = serializeObject(params);

	history.pushState({}, null, path + '?' + params);
};

const groupParamsByKey = (params) =>
	[...params.entries()].reduce((acc, tuple) => {
		// getting the key and value from each tuple
		const [key, val] = tuple;
		if (acc.hasOwnProperty(key)) {
			// if the current key is already an array, we'll add the value to it
			if (Array.isArray(acc[key])) {
				acc[key] = [...acc[key], val];
			} else {
				// if it's not an array, but contains a value, we'll convert it into an array
				// and add the current value to it
				acc[key] = [acc[key], val];
			}
		} else {
			// plain assignment if no special case is present
			acc[key] = val;
		}

		return acc;
	}, {});

export const getParams = function () {
	let searchParams = new URLSearchParams(window.location.search);

	let params = groupParamsByKey(searchParams);

	let newParams = {};

	for (let key in params) {
		if (params.hasOwnProperty(key)) {
			if (key.length !== 2) {
				newParams[key] = params[key];
			}

			// Get the last two characters of the string
			const lastTwoChars = key.slice(-2);
			// Check if the last two characters are '[]'
			if (lastTwoChars === '[]') {
				let tempKey = key.slice(0, -2);
				let val = params[key];
				if (typeof val === 'string') {
					val = [val];
				}
				newParams[tempKey] = val;
				delete newParams[key];
			} else {
				newParams[key] = params[key];
			}
		}
	}

	newParams = verifyParams(newParams);

	return newParams;
};
