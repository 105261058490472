<template>
	<div class="c-brands-empty">
		<div class="c-brands-empty__inner">
			<h2>Track brands - while you're asleep</h2>
			<article class="article-text">
				<p>Use Swipekit's Brand Tracker to keep track of a brand's Facebook Ad library page.</p>
				<ul class="c-list-fancy">
					<li>No need to visit the Facebook Ad library to keep track of your competitors.</li>
					<li>Save the brand's latest ads from Swipekit.</li>
					<li>Get insights on periodic brand data.</li>
				</ul>

				<p>Start using Swipekit Brand Tracker today.</p>
				<br />
				<button type="button" @click.prevent="onAddBrand" class="btn btn-lg btn-primary">Track brand</button>
			</article>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		onAddBrand: function () {
			this.$store.dispatch('app/setAddBrand', true);
		},
	},
};
</script>

<style lang="scss">
.c-brands-empty {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	&__inner {
		display: inline-block;
		margin: 0 auto;
		border: var(--color-bg-4) solid 1px;
		border-radius: 10px;
		padding: var(--spacer-sm) var(--spacer);

		background-color: var(--color-bg-1);
		width: calc(var(--header-width));
		margin: var(--spacer-sm) auto;

		box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(9, 9, 11, 0.08) 0px 0px 0px 1px, rgba(9, 9, 11, 0.08) 0px 1px 2px -1px, rgba(9, 9, 11, 0.04) 0px 2px 4px 0px;
		border-radius: 20px;

		h2 {
			font-size: var(--font-size-xl);
			line-height: 1.4;
		}

		p {
			margin-bottom: 0;
		}

		ul {
			text-align: left;
		}
	}
}
</style>
