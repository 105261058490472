<template>
	<div class="c-board-page">
		<Board ref="library" :slug="slug"></Board>
	</div>
</template>

<script>
import Board from '@swipekit/components/board';

export default {
	components: {
		Board,
	},

	props: {
		slug: {},
	},

	computed: {},
};
</script>

<style lang="scss">
.c-board-page {
	// height: 100vh;
	// position: relative;
	// overflow-y: auto;
}
</style>
