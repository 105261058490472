import { render, staticRenderFns } from "./breakdown.vue?vue&type=template&id=ce01b902&"
import script from "./breakdown.vue?vue&type=script&lang=js&"
export * from "./breakdown.vue?vue&type=script&lang=js&"
import style0 from "./breakdown.vue?vue&type=style&index=0&id=ce01b902&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports