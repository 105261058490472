<template>
	<div class="form-group c-input-tags">
		<label v-if="label" class="form__label" :for="slug">{{ label }}</label>
		<component :is="dynamicComponent">
			<vue-tags-input
				:autocomplete-min-length="1"
				:maxLength="22"
				:max-tags="5"
				v-model="tag"
				:placeholder="placeholder"
				:tags="tags"
				:validation="validation"
				:autocompleteItems="items"
				@tags-changed="onTagsChanged"
			>
			</vue-tags-input>
		</component>
		<InputError v-if="hasError" :message="errorMessage"></InputError>
		<InputDescription v-if="description" :message="description"></InputDescription>
	</div>
</template>

<script>
import InputError from './input-error';
import InputDescription from './input-description';
import slugify from '@swipekit/lib/slugify.js';

export default {
	components: {
		InputError,
		InputDescription,
	},

	data: function () {
		return {
			tag: '',
			tags: [],
			validation: [],
		};
	},

	watch: {
		tags: function () {
			let tags = JSON.parse(JSON.stringify(this.tags));
			this.$emit('input', this.cleanTags(tags));
		},
		value: function () {
			let valueArr = this.value || [];

			let currentArr = this.tags || [];

			if (!this.value) {
				this.tags = [];
				return;
			}

			if (valueArr.length !== currentArr.length) {
				this.tags = this.value;
			}
		},
	},

	props: {
		label: {
			type: String,
			default: '',
		},
		value: {},
		placeholder: {
			type: String,
			default: '+ Add tag',
		},
		noStyle: {
			type: Boolean,
			default: false,
		},
		description: {
			type: String,
			default: '',
		},
		hasError: {
			type: Boolean,
			default: false,
		},
		errorMessage: {},
		// autocompleteItems: {
		// 	type: Array,
		// 	default: function () {
		// 		return [];
		// 	},
		// },
	},

	computed: {
		workspace: function () {
			return this.$store.getters['workspaces/all'];
		},
		dynamicComponent: function () {
			let standalone = this.$store.getters['standalone'];
			if (standalone) {
				return 'client-only';
			} else {
				return 'div';
			}
		},
		slug: function () {
			return slugify(this.label || `input_${Math.floor(Math.random() * 100)}`);
		},
		viewCache: function () {
			return this.$store.getters['ads/viewCache'];
		},
		items: function () {
			let items = [];
			// let viewCache = this.viewCache || [];

			// if (!viewCache) {
			// 	items = items.map((item) => {
			// 		if (typeof item === 'string') {
			// 			return {
			// 				text: item,
			// 			};
			// 		}
			// 		return item;
			// 	});
			// 	return items;
			// }

			//console.log(this.workspace);

			let workspace = this.workspace || {};

			let workspaceTags = workspace.tags2 || [];

			for (let i = 0; i < workspaceTags.length; i++) {
				let wtag = workspaceTags[i];

				if (wtag.name) {
					items.push({
						text: wtag.name,
					});
				} else if (typeof wtag === 'string') {
					items.push({
						text: wtag,
					});
				}
			}

			items = items.slice(0, 20);

			// for (let i = 0; i < viewCache.length; i++) {
			// 	let tags = viewCache[i].tags;

			// 	if (tags) {
			// 		// ignore objects
			// 		for (let j = 0; j < tags.length; j++) {
			// 			if (typeof tags[j] === 'string') {
			// 				items.push({
			// 					text: tags[j],
			// 				});
			// 			}
			// 		}
			// 	}
			// }

			items = [...new Set(items)];

			// items = items.map((item) => {
			//   return {
			//     text: item,
			//   };
			// });

			if (!items) {
				items = [];
			}

			items.sort((a, b) => a.text.localeCompare(b.text));

			return items;
		},
	},

	methods: {
		onTagsChanged: function (newTags) {
			this.tags = newTags;
		},
		cleanTags: function (tags) {
			for (let i = 0; i < tags.length; i++) {
				if (tags[i].tiClasses) {
					delete tags[i].tiClasses;
				}
				if (tags[i].text) {
					tags[i] = tags[i].text;
				}
			}

			return tags;
		},
	},

	mounted: function () {
		if (this.value && this.value.length > 0) {
			let tags = this.value;

			tags = tags.map((tag) => {
				if (typeof tag === 'string') {
					return {
						text: tag,
					};
				}
				return tag;
			});

			this.tags = tags;
		}
	},
};
</script>

<style lang="scss">
.c-input-tags {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-bottom: var(--margin-lg);

	> div {
		width: 100%;
	}

	.vue-tags-input {
		margin-bottom: var(--margin);
		width: 100%;
		max-width: initial;
		background-color: transparent !important;

		.ti-input {
			border: none;
			min-height: 50px;
			padding: 0;
		}
		.ti-autocomplete {
			overflow: hidden;
			font-size: var(--font-size-xs);

			background-color: var(--color-bg-1);
			border-radius: var(--border-radius);
			border: none;
			box-shadow: var(--item-box-shadow);
			padding: var(--margin);

			li {
				display: inline-block;
				position: relative;
				margin-right: 4px;
				margin-bottom: 4px;
				background-color: var(--color-bg-4);
				padding: 2px 4px;
				padding-right: 10px;
				font-size: var(--font-size-sm);
				line-height: 1;
				border-radius: var(--border-radius);
				cursor: pointer;
				transition: all var(--transition-time-sm) ease-out;

				&:after {
					content: '';
					display: block;
					position: absolute;
					top: calc(50% - 2px);
					right: 6px;
					width: 4px;
					height: 4px;
					background-color: currentColor;
					border-radius: 2px;
				}

				&:hover,
				&:focus,
				&.active,
				&.ti-selected-item {
					outline: none;
					background-color: var(--color-primary);
					color: var(--color-button-font);
				}
			}
		}
		.ti-tag {
			margin: 0;
			margin-right: 4px;
			margin-bottom: 4px;
			max-height: 26px;
			cursor: pointer;
			padding: var(--margin) var(--spacer-sm);
			padding-right: var(--margin-lg);
			color: var(--color-font);
			background-color: var(--color-bg-4);
			border-radius: var(--border-radius);

			display: flex;
			justify-content: center;
			align-items: center;

			letter-spacing: 0.3px;
			font-size: var(--font-size-sm);

			&.ti-deletion-mark {
				color: var(--color-button-font);
			}

			&:hover,
			&:active {
				opacity: 0.85;
			}
		}
		.ti-tags {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			line-height: 1em;
			//padding: var(--margin);
			border-radius: 9px;
			border: var(--color-input-background) solid 1px;
			padding: 4px 4px 0 4px;
			background-color: transparent;
			background-color: var(--color-bg-2);
			list-style: none;
			box-sizing: border-box;

			.ti-actions {
				i {
					transition: background-color var(--transition-time) linear;
					&:hover {
						background-color: rgba(0, 0, 0, 0.1);
					}
				}
			}

			.ti-new-tag-input-wrapper {
				min-height: 26px;
				display: block;
				//display: flex;
				//flex: 1 0 auto;
				font-size: var(--font-size);
				margin: 0;
				margin-right: 0;
				margin-bottom: 4px;
				padding: 0;
				background-color: transparent;

				input {
					padding: var(--margin) var(--margin-lg);
					-webkit-appearance: none;
					background-color: transparent;
					color: var(--color-font);
					font-size: var(--font-size-sm);
					width: 100%;
					box-sizing: border-box;
					border: none;
					border-radius: var(--border-radius);

					&:focus {
						border-color: var(--color-input-background-light);
						@include focus();
						outline: none;
					}
				}
			}
		}

		[class^='ti-icon-'] {
			font-family: 'icomoon' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
		}
		.ti-icon-close {
			&:before {
				content: '\e901';
			}
		}
	}
}
</style>
