<template>
	<div class="c-block-view-assets">
		<div class="c-block-view-assets__item" v-for="(asset, i) in assets" :key="i">
			<Header :title="getHeaderTitle(i)" :asset="asset"></Header>
			<div class="c-block-view-assets__renderer" :style="computedViewerStyle">
				<video v-if="asset.type === 'video'" :poster="setCover(asset)" controls>
					<source :src="`${assetUrl}/${asset.assetPath}`" type="video/mp4" />
				</video>
				<lite-youtube v-if="asset.type === 'youtube'" :videoid="asset.id"></lite-youtube>
				<img v-if="asset.type !== 'video' && asset.type !== 'youtube'" :src="`${assetUrl}/${asset.assetPath}`" />
			</div>
			<article v-if="asset.title || asset.body">
				<section title="Ad title">
					<Copy :text="asset.title"></Copy>
					<strong>
						{{ asset.title }}
					</strong>
				</section>
				<section title="Ad Copy" v-if="asset.body">
					<Copy :text="asset.body"></Copy>
					<p>{{ asset.body }}</p>
				</section>
				<a :href="getAssetLinkUrl(asset)" target="_blank" v-if="getAssetLinkUrl(asset) && getAssetCtaText(asset)" type="button" class="btn btn-box">
					{{ getAssetCtaText(asset) }}
				</a>
				<button v-if="!getAssetLinkUrl(asset) && getAssetCtaText(asset)" type="button" class="btn btn-box">
					{{ getAssetCtaText(asset) }}
				</button>
				<section v-if="asset.link_description" title="Ad link description">
					<Copy :text="asset.link_description"></Copy>
					<small>{{ asset.link_description }}</small>
				</section>
			</article>
		</div>
		<div v-if="screenshot && !showLandingPageScreenshots" class="c-block-view-assets__item">
			<button type="button" @click="onLandingPageView" class="btn">
				<span> View Landing page screenshots </span>
			</button>
		</div>
		<template v-if="showLandingPageScreenshots">
			<div class="c-block-view-assets__item" v-if="screenshot && screenshot.assets[0]">
				<Header title="Landing page screenshot(desktop)" :asset="screenshot.assets[0]"></Header>
				<div class="c-block-view-assets__renderer">
					<img :src="`${assetUrl}/${screenshot.assets[0].assetPath}`" />
				</div>
			</div>
			<div class="c-block-view-assets__item" v-if="screenshot && screenshot.assets[1]">
				<Header title="Landing page screenshot(mobile)" :asset="screenshot.assets[1]"></Header>
				<div class="c-block-view-assets__renderer">
					<img :src="`${assetUrl}/${screenshot.assets[1].assetPath}`" />
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import Header from './asset-header';
import Copy from './copy';

import api from '@swipekit/lib/api.js';

export default {
	components: {
		Header,
		Copy,
	},

	data: function () {
		return {
			showLandingPageScreenshots: false,
		};
	},

	props: {
		block: {},
	},

	computed: {
		screenshot: function () {
			return this.block.screenshot || this.block.screenshotBlock;
		},
		computedViewerStyle: function () {
			return {};
			let assetRatio = 1;
			if (this.block && this.block.assetRatio) {
				assetRatio = this.block.assetRatio;
			}

			let padding = assetRatio * 100;
			let str = `padding-bottom:${padding}%;`;

			return str;
		},
		baseUrl: function () {
			return this.$store.getters['config/baseUrl'];
		},
		assetUrl: function () {
			return this.$store.getters['config/assetUrl'];
		},
		assets: function () {
			let assets = this.block.assets;

			return assets;
		},
	},

	methods: {
		onLandingPageView: function () {
			this.showLandingPageScreenshots = true;

			let payload = {
				name: `user.screenshotsviewed`,
				blockId: this.block.id,
			};

			api.api.track(payload);
		},
		getAssetCtaText: function (asset) {
			if (asset.cta_text) {
				return asset.cta_text || '';
			}
			return null;
		},
		getAssetLinkUrl: function (asset) {
			if (asset.link_url) {
				return asset.link_url || '';
			}
			return null;
		},
		setCover: function (asset) {
			if (!asset.cover) {
				return;
			}

			let assetUrl = this.assetUrl;

			let url = `${assetUrl}/${asset.cover.assetPath}`;

			return url;
		},
		getHeaderTitle: function (i) {
			if (this.block && this.block.meta && this.block.meta.dco) {
				let index = i + 1;
				let str = this.getVariation(index, 'DCO', 'asset');

				return str;
			} else if (this.block && this.block.meta && this.block.meta.dpa) {
				let index = i + 1;
				let str = this.getVariation(index, 'DPA', 'asset');

				return str;
			} else if (this.block.assetType === 'carousel') {
				let index = i + 1;
				let str = this.getVariation(index, 'Carousel', 'slide');

				return str;
			} else {
				return 'Primary Asset';
			}
		},
		getVariation: function (i, pre = 'DCO', post = 'slide') {
			if (i === 1) {
				return `${pre} – 1st ${post}`;
			}
			if (i === 2) {
				return `${pre} – 2nd ${post}`;
			}
			if (i === 3) {
				return `${pre} – 3rd ${post}`;
			}

			return `${pre} – ${i}th ${post}`;
		},
	},
};
</script>

<style lang="scss">
.c-block-view-assets {
	width: 100%;
	padding: var(--margin-lg);

	&__item {
		max-width: 600px;
		margin: 0 auto;
		position: relative;
		padding-top: var(--spacer-sm);
		padding-bottom: var(--spacer-sm);

		> .btn {
			width: 100%;
		}

		label {
			font-size: var(--font-size);
			font-weight: 500;
		}

		// video,
		// img {
		//   position: absolute;
		//   top: 0;
		//   left: 0;
		//   width: 100%;
		//   //height: calc(80px - (var(--margin) * 2));
		//   height: auto;
		//   object-fit: cover;
		//   border-radius: var(--border-radius);
		// }

		video,
		img {
			width: 100%;
			height: auto;
			border-radius: var(--border-radius);
			box-shadow: 0 0 0 1px rgba(17, 24, 28, 0.08), 0 1px 2px -1px rgba(17, 24, 28, 0.08), 0 2px 4px rgba(17, 24, 28, 0.04);
		}

		article {
			padding: var(--margin-lg);
			white-space: break-spaces;

			> section {
				display: grid;
				grid-template-columns: 24px 1fr;
			}

			> a,
			> button {
				margin-bottom: var(--margin-lg);
			}

			strong {
				display: block;
				font-weight: 500;
				margin-bottom: var(--margin-lg);
				opacity: 0.85;
			}

			p {
				opacity: 0.8;
			}
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: var(--color-bg-5);
		}

		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	&__renderer {
		width: 100%;
		position: relative;
	}

	@media screen and (max-width: 1799px) {
		.c-block-view-assets__item {
			max-width: 100%;
			padding-left: var(--spacer-lg);
			padding-right: var(--spacer-lg);
		}
	}

	@media screen and (max-width: 1400px) {
		.c-block-view-assets__item {
			padding-left: var(--spacer-sm);
			padding-right: var(--spacer-sm);
		}
	}

	@media screen and (max-width: 960px) {
		.c-block-view-assets__item {
			padding-left: var(--margin-lg);
			padding-right: var(--margin-lg);
		}
	}

	@media screen and (min-width: 1800px) {
		.c-block-view-assets__item {
			max-width: 600px;
		}
	}
}
</style>
