<template>
	<div class="c-brands-slug">
		<PageHeader v-if="brand" byline="Brand" :breadcrumbs="breadcrumbs" :showMenu="true">
			<template v-slot:title> {{ brand.name }} </template>

			<template v-slot:menu>
				<router-link :class="[{ active: fullCleanPath === '/' }]" :to="`/brands/${slug}`">
					<h6>Insights</h6>
				</router-link>
				<router-link :class="[{ active: fullCleanPath === '/ads' }]" :to="`/brands/${slug}/ads`">
					<h6>Ads</h6>
				</router-link>
				<!-- <router-link :class="[{ active: fullCleanPath === '/landing-pages' }]" :to="`/brands/${slug}/landing-pages`">
					<h6>Landing pages</h6>
				</router-link> -->
			</template>
		</PageHeader>
		<div class="c-brands-slug__inner" v-if="brand">
			<Insight v-if="fullCleanPath === '/'" :brand="brand"></Insight>
			<router-view></router-view>
		</div>
		<div class="c-brands-slug__empty-state" v-if="!brand && !error">
			<span class="c-spinner"></span>
		</div>
		<div class="c-brands-slug__error" v-if="error">
			<article>
				<h3>404 brand not found</h3>
				<p>
					<router-link to="/brands"> Go back </router-link>
				</p>
			</article>
		</div>
	</div>
</template>

<script>
import PageHeader from '@swipekit/components/utils/page-header';
import Insight from './insight';
import api from '@swipekit/lib/api';

export default {
	components: {
		PageHeader,
		Insight,
	},

	data: function () {
		return {
			brand: null,
			error: false,
		};
	},

	computed: {
		breadcrumbs: function () {
			return [
				{
					name: 'Back',
					url: '/brands',
				},
				{
					name: `Brand data`,
				},
			];
		},
		slug: function () {
			if (this.$route.params) {
				return this.$route.params.slug;
			} else {
				return '';
			}
		},
		fullCleanPath: function () {
			let path = this.$route.fullPath;

			if (this.$route.name === 'BrandsSlug') {
				return '/';
			}

			if (this.$route.name === 'Ads') {
				return '/ads';
			}

			if (this.$route.name === 'LandingPages') {
				return '/landing-pages';
			}

			path = path.split('?')[0];

			return path;
		},
	},

	methods: {
		async loadData() {
			let slug = this.$route.params.slug;
			let brand = await api.brands.getOne(slug);

			if (!brand) {
				this.error = true;
				return;
			}

			brand.tracker = brand.trackers[0];

			delete brand.trackers;

			this.brand = brand;

			return brand;
		},
	},

	mounted: function () {
		this.loadData();
	},
};
</script>

<style lang="scss">
.c-brands-slug {
	&__inner {
		position: relative;
	}

	&__empty-state {
		padding: var(--spacer);
		padding-top: calc(var(--spacer-lg) * 2);
		display: flex;
		align-items: center;
		justify-content: center;

		.c-spinner {
			width: 48px;
			height: 48px;
			border-width: 3px;
			border-color: transparent;
			border-bottom-color: var(--color-primary);
		}
	}

	&__error {
		display: flex;
		align-items: center;
		justify-content: center;

		padding: var(--spacer);
		padding-top: calc(var(--spacer-lg) * 2);
	}
}
</style>
