let store = {
	namespaced: true,
	state: {
		messages: {
			serverError: {
				message: 'Something went wrong server side. Please try again.',
			},
			clientError: {
				message: "Couldn't handle your request",
			},
			genericError: {
				message: 'Something went wrong',
			},
		},

		message: null,
		messageTrigger: false,
	},
	getters: {
		message: function (state) {
			return state.message;
		},
		messageTrigger: function (state) {
			return state.messageTrigger;
		},
	},
	mutations: {
		SET_MESSAGE: function (state, message) {
			state.message = message;
		},
		TRIGGER: function (state) {
			state.messageTrigger = !state.messageTrigger;
		},
	},
	actions: {
		async init(store) {},

		async sendMessage(store, name) {
			let message = null;
			if (typeof name === 'string') {
				message = store.state.messages[name];
			} else {
				message = name;
			}
			store.commit('SET_MESSAGE', message);
			store.commit('TRIGGER');
		},

		// 400, 401, etc
		async onServerError(store, err) {
			store.dispatch('sendMessage', 'serverError');
		},

		// timeout, etc
		async onClientError(store, err) {
			store.dispatch('sendMessage', 'clientError');
		},

		// When yolo
		async onGenericError(store, err) {
			store.dispatch('sendMessage', 'genericError');
		},

		async onLogin(store) {
			let user = store.rootGetters['users/user'];
			let message = {
				message: `Welcome back ${user.name}`,
				status: 'success',
			};
			store.dispatch('sendMessage', message);
		},

		async onSignup(store) {
			let user = store.rootGetters['users/user'];
			let message = {
				message: `Welcome ${user.name}! Please check your inbox to verify your account email.`,
				status: 'success',
				timer: 6000,
			};
			store.dispatch('sendMessage', message);
		},

		async onLogout(store) {
			let user = store.rootGetters['users/user'];
			if (!user) {
				return;
			}
			let message = {
				message: `See you soon ${user.name}`,
			};
			store.dispatch('sendMessage', message);
		},

		async onPasswordReset(store) {
			let message = {
				message: `Your password has been reset successfully!`,
				status: 'success',
			};
			store.dispatch('sendMessage', message);
		},

		async onShowWidget(store) {
			let message = {
				timer: 2500,
				message: `Crop tool is active. Drag to select an area. Press Esc to disable it.`,
			};
			store.dispatch('sendMessage', message);
		},

		async onCropInactive(store) {
			let message = {
				timer: 2500,
				message: `Crop tool is disabled.`,
			};
			store.dispatch('sendMessage', message);
		},

		async onExtensionAuth(store) {
			let message = {
				status: 'success',
				message: `Swipekit extension is authenticated!`,
			};
			store.dispatch('sendMessage', message);
		},

		async onAssetError(store, err) {
			let message = {
				timer: 3000,
				status: 'failure',
				message: `Screenshot couldn't be saved :( Please try again`,
			};
			store.dispatch('sendMessage', message);
		},

		async onAssetSaved(store, obj) {
			let message = {
				message: `Screenshot saved!`,
				timer: 2000,
				status: 'success',
			};
			store.dispatch('sendMessage', message);
		},

		async onAssetSaving(store, obj) {
			let message = {
				message: `Saving screenshot..`,
				timer: 2000,
			};
			store.dispatch('sendMessage', message);
		},

		async onBoardCreate(store) {
			let message = {
				message: `New board created!`,
				status: 'success',
			};
			store.dispatch('sendMessage', message);
		},

		async onBulkUpdate(store) {
			let message = {
				message: `Ads have been bulk updated`,
				status: 'success',
			};
			store.dispatch('sendMessage', message);
		},

		async onBlockSave(store) {
			let message = {
				message: `Ad saved!`,
				status: 'success',
				timer: 1000,
			};
			store.dispatch('sendMessage', message);
		},

		async onUserUpdate(store) {
			let message = {
				message: `Your settings have been updated`,
				status: 'success',
			};
			store.dispatch('sendMessage', message);
		},

		async onUserPasswordUpdate(store) {
			let message = {
				message: `Your password has been changed successfully`,
				status: 'success',
			};
			store.dispatch('sendMessage', message);
		},

		async onExtensionAuth(store) {
			let message = {
				message: `Extension is authenticated!`,
				status: 'success',
			};
			store.dispatch('sendMessage', message);
		},

		async onAcceptInvite(store) {
			let message = {
				message: `Welcome ${store.getters['users/user'].name}!`,
				status: 'success',
			};
			store.dispatch('sendMessage', message);
		},

		async onUserInvited(store) {
			let message = {
				message: `Teammate invited to Workspace!`,
				status: 'success',
			};
			store.dispatch('sendMessage', message);
		},

		async onAdUpdate(store) {
			let message = {
				message: `Ad has been updated`,
				status: 'success',
			};
			store.dispatch('sendMessage', message);
		},

		async onAdDelete(store) {
			let message = {
				message: `Ad has been removed`,
			};
			store.dispatch('sendMessage', message);
		},

		async onAdRemovedFromBoard(store) {
			let message = {
				message: `Ad removed from board`,
			};
			store.dispatch('sendMessage', message);
		},

		async onRemovingUser(store) {
			let message = {
				message: `Removing Workspace User. This may take a few seconds.`,
			};
			store.dispatch('sendMessage', message);
		},

		async onDownloadStarted(store) {
			let message = {
				message: `Downloading your file..`,
				timer: 2000,
			};
			store.dispatch('sendMessage', message);
		},

		async onDownloadFailed(store) {
			let message = {
				message: `Couldn't download file`,
				status: 'failure',
			};
			store.dispatch('sendMessage', message);
		},

		async onAccountDeleted(store) {
			let message = {
				message: `Removing your Account. This will take a few seconds..`,
			};
			store.dispatch('sendMessage', message);
		},

		async onInvoiceDownload(store) {
			let message = {
				message: `Downloading your invoice..`,
			};
			store.dispatch('sendMessage', message);
		},

		async onEmailVerified(store) {
			let message = {
				message: `Your account has been successfully verified! You can now save ads.`,
				status: 'success',
				timer: 4000,
			};
			store.dispatch('sendMessage', message);
		},

		async onPushEmailVerify(store) {
			let message = {
				message: `This feature is locked. Please verify your account email to unlock it.`,
				status: 'warning',
			};
			store.dispatch('sendMessage', message);
		},

		async onAdLinkCopy(store) {
			let message = {
				message: `Ad link copied`,
				status: 'success',
			};
			store.dispatch('sendMessage', message);
		},

		async onUnsubscribe(store) {
			let message = {
				message: `Your subscription has been cancelled`,
				status: 'success',
			};
			store.dispatch('sendMessage', message);
		},

		async onTranscriptCreated(store) {
			let message = {
				message: `Transcript generated!`,
				status: 'success',
			};
			store.dispatch('sendMessage', message);
		},

		async onTrackerRemoved(store) {
			let message = {
				message: `Removing tracker`,
			};
			store.dispatch('sendMessage', message);
		},
	},
};

export default store;
