<template>
	<div class="c-brands-page" v-if="isAuth">
		<BrandsSlug></BrandsSlug>
	</div>
</template>

<script>
import BrandsSlug from '@swipekit/components/brand-slug';

export default {
	components: {
		BrandsSlug,
	},

	computed: {
		isAuth: function () {
			return this.$store.getters['users/isAuth'];
		},
		standalone: function () {
			return this.$store.getters['standalone'];
		},
	},

	methods: {},

	mounted: function () {},
};
</script>

<style lang="scss">
.c-brands-page {
}
</style>
