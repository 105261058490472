<template>
	<div class="c-modal-onboarding">
		<Modal v-model="options.active" :closable="false">
			<!-- <Onboarding :initialStage="initialStage"></Onboarding> -->
			<Onboarding2 v-if="options.active" :initialStage="initialStage"></Onboarding2>
		</Modal>
	</div>
</template>

<script>
import Modal from './index.vue';
import Onboarding from '@swipekit/components/onboarding';
import Onboarding2 from '@swipekit/components/onboarding2';

export default {
	components: {
		Modal,
		Onboarding,
		Onboarding2,
	},

	data: function () {
		return {
			options: {
				name: 'modal-onboarding',
				active: false,
			},
			initialStage: 0,
		};
	},

	watch: {
		'options.active': {
			handler: function () {},
		},
		addId: {
			handler: function () {
				if (this.addId) {
					this.options.active = true;
				} else {
					this.options.active = false;
				}
			},
			immediate: true,
		},
	},

	computed: {
		addId: function () {
			let condition = this.$store.getters['app/showOnboarding'];
			//condition = true;
			return condition;
		},
	},

	methods: {
		onSubmit: function () {
			this.options.active = false;
		},
		activate: function () {
			this.options.active = true;
		},
		closeModal: function () {
			this.$store.dispatch('trackers/setAdd', null);
		},
	},

	mounted: function () {
		let user = this.$store.getters['users/user'];

		let workspace = this.$store.getters['workspaces/all'];

		let isAuth = this.$store.getters['users/isAuth'];

		// ignore everything if user isn't logged in in the first place
		if (!isAuth) {
			return;
		}

		// ignore everything if user has already been onboarded
		if (user && user.onboarded && workspace && workspace.activated) {
			return;
		}

		let query = this.$route.query;

		if (query && query.onboard && query.onboard === '2') {
			this.initialStage = 4;

			let query = Object.assign({}, this.$route.query);
			delete query.onboard;
			this.$router.replace({ query });

			this.$store.dispatch('app/toggleOnboarding', true);
		} else {
			// Show onboarding since user.onboarded is false
			this.$store.dispatch('app/toggleOnboarding', true);
		}

		//this.options.active = true;
	},
};
</script>

<style lang="scss">
.c-modal-onboarding {
	.vfm {
		.modal-content {
			width: 100%;
			height: 100%;
			margin-top: 0;
			background-color: transparent;
			background: none;
			border: none;
			box-shadow: none;

			.modal__content {
				height: 100%;
			}
		}
		.modal-container {
			padding: 0;
		}
		.vfm__overlay {
			background-color: var(--color-bg-1) !important;
		}
	}

	.modal__title {
		padding: 0;
	}

	.modal__content {
		padding: 0;
	}

	.btn {
		margin-right: 5px;
		margin-bottom: 5px;
	}
}
</style>
