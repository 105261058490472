<template>
	<div class="c-brand-slug-links">
		<strong> Landing page links </strong>
		<article v-for="(link, i) in insight.links" :key="i">
			<a :href="link.link" target="_blank" rel="noreferrer">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M12.8123 4.64988C12.485 4.38797 12 4.62106 12 5.04031V9.04883C7.04515 9.54065 3.15442 13.6496 3.00449 18.6976C3.00163 18.7938 3.12408 18.8361 3.18388 18.7606C5.24435 16.1606 8.54522 14.6622 12 15V18.9597C12 19.3789 12.485 19.612 12.8123 19.3501L21.512 12.3904C21.7622 12.1903 21.7622 11.8097 21.512 11.6096L12.8123 4.64988Z"
						stroke-width="1.5"
						stroke="currentColor"
						stroke-linecap="round"
					/>
				</svg>
			</a>
			<span>
				{{ link.link }}
			</span>
			<span>
				{{ link.count }}
			</span>
		</article>
	</div>
</template>

<script>
import Copy from '@swipekit/components/block-view/copy';
export default {
	components: {
		Copy,
	},

	props: {
		insight: {},
	},
};
</script>

<style lang="scss">
.c-brand-slug-links {
	margin-top: var(--margin-lg);

	.c-block-view-copy {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: var(--margin-sm);
	}

	article {
		display: grid;
		grid-template-columns: 32px 1fr 48px;
		align-items: center;
		padding: var(--margin-sm);

		&:hover {
			background-color: var(--color-bg-3);
		}

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: var(--margin-sm);
			color: var(--color-font);

			&:hover,
			&:active {
				color: var(--color-primary);
			}
		}

		span:first-of-type {
			word-break: break-all;
		}

		span:last-child {
			text-align: right;
			font-feature-settings: 'case' 0, 'dlig' 0, 'frac' 0, 'dnom' 0, 'numr' 0, 'subs' 0, 'sups' 0, 'tnum', 'zero' 0, 'ss01', 'ss02' 0, 'ss03' 0, 'ss04', 'ss05' 0, 'ss06' 0, 'ss07' 0, 'ss08' 0,
				'cv01' 0, 'cv02' 0, 'cv03' 0, 'cv04' 0, 'cv05' 0, 'cv06' 0, 'cv07' 0, 'cv08' 0, 'cv09' 0, 'cv10' 0, 'cv11' 0, 'cv12' 0, 'cv13' 0, 'cpsp' 0, 'c2sc' 0, 'salt' 0, 'aalt' 0, 'calt', 'ccmp', 'locl',
				'kern';
		}
	}

	strong {
		display: block;
		margin-bottom: var(--margin);
	}
}
</style>
