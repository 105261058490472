import * as localForage from 'localforage';
import { http } from './http.js';
import httpTools from './http.js';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

let store = null;
let mode = ''; //window.mode;

//localForage.defineDriver(extensionDriver);

let setStorage = async function (key = 'um/token', data) {
	let obj = {};
	obj[key] = data;
	return new Promise((resolve, reject) => {
		chrome.storage.local.set(obj, function (hmm) {
			resolve(hmm);
		});
	});
};

let getStorage = async function (key = 'um/token') {
	return new Promise((resolve, reject) => {
		if (!chrome.storage) {
			resolve(null);
		} else {
			chrome.storage.local.get([key], (data) => {
				data = data[key] || null;
				resolve(data);
			});
		}
	});
};

if (typeof window === 'undefined') {
	store = {
		async setItem() {
			return true;
		},
		async getItem() {
			return true;
		},
		async removeItem() {
			return true;
		},
	};
} else {
	store = localForage.createInstance({
		name: 'um',
		driver: localForage.LOCALSTORAGE,
	});
}

function Storage() {
	this.get = async function (key) {
		let res = null;
		res = await store.getItem(key);
		return res;
	};

	this.set = async function (key, data) {
		let res = null;
		res = await store.setItem(key, data);
		return res;
	};

	this.remove = async function (key) {
		let res = null;
		res = await store.removeItem(key);
		return res;
	};
}

let storage = new Storage();
let self = null;

let api = {
	init: function (m) {
		self = this;
		mode = m || window.mode;
		if (mode === 'EXTENSION') {
			//store.setDriver('extensionDriver');
		}
		return true;
	},

	api: {
		async getOptions() {
			let options = (await storage.get('um/options')) || {};
			return options;
		},
		async setOption(key, value) {
			let options = await this.getOptions();

			options = {
				...options,

				[key]: value,
			};

			await await storage.set('um/options', options);
		},
		async track(payload) {
			let body = payload;
			let response = null;
			try {
				response = await http.post(`/api/event`, body);
			} catch (err) {
				throw err;
			}
			if (response.status === 201) {
				return response.data;
			} else {
				return false;
			}
		},
		async getPrices() {
			let url = `https://api.swipekit.app/api/prices`;

			let response = await axios.get(url).catch((err) => {});

			return response.data || [];
		},
		async getOne(adId) {
			let baseUrl = `https://api.swipekit.app`;
			let response = await http.get(`${baseUrl}/api/ad/${adId}`);

			let resources = null;
			if (response && response.data) {
				resources = response.data;
			}

			return resources;
		},
		async doAction(id, action, payload = {}) {
			let body = payload;
			let response = null;
			try {
				response = await http.post(`/api/${id}/action/${action}`, body);
			} catch (err) {
				throw err;
			}
			if (response.status === 201) {
				return response.data;
			} else {
				return false;
			}
		},
	},

	workspaces: {
		async downloadInvoice(invoiceId, invoiceNumber) {
			let fileName = `invoice-${invoiceNumber}.pdf`;
			let config = {
				responseType: 'arraybuffer',
			};
			let response = await http.get(`/workspace/invoices/${invoiceId}`, config).catch((err) => {
				throw err;
			});

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName); //or any other extension
			document.body.appendChild(link);
			link.click();

			return response.data || [];
		},

		async resendVerification(payload) {
			let response = await http.post(`/workspace/${payload.id}/resend-verification`).catch((err) => {
				throw err;
			});

			return response.data || null;
		},

		async verifyToken(payload) {
			let response = await http.post(`/workspace/${payload.id}/verify-token`, payload).catch((err) => {
				throw err;
			});

			return response.data || null;
		},

		async getInvoices() {
			let response = await http.get(`/workspace/invoices`).catch((err) => {
				throw err;
			});

			return response.data || [];
		},

		async get(type) {
			let response = await http.get(`/workspace`).catch((err) => {
				throw err;
			});

			return response.data || null;
		},

		async invite(form) {
			let response = null;
			try {
				response = await http.post(`/user/invite`, form);
			} catch (err) {
				if (err.response && err.response.data) {
					throw err.response.data || [];
				} else {
					throw [
						{
							type: 'GENERIC',
							message: 'Something went wrong, please try again',
						},
					];
				}
			}

			return response.data;
		},

		async removeUser(form) {
			let config = {
				data: form,
			};
			let response = await http.delete(`/workspace/remove-user`, config).catch((err) => {
				throw err;
			});

			return response.data || null;
		},

		async fetchAndStore() {
			let resources = await this.get();
			await setStorage('um/boards', resources);
			return resources;
		},

		async add(resource) {
			let response = await http.post(`/board`, resource);
			if (!response) {
				return null;
			}
			resource = response.data;
			return resource;
		},

		async update(resource) {
			let response = null;

			if (resource.ads) {
				delete resource.ads;
			}
			try {
				response = await http.put(`/workspace/${resource.id}`, resource);
			} catch (err) {
				console.log(err);
				throw err;
			}
			if (!response) {
				return null;
			}
			resource = response.data;
			return resource;
		},

		async delete(id) {
			let response = null;
			try {
				response = await http.delete(`/board/${id}`);
			} catch (err) {
				console.log(err);
			}
			if (response.status === 201 || response.status === 200) {
				return true;
			} else {
				return false;
			}
		},

		async cancelSubscription(id, form) {
			let response = null;
			try {
				response = await http.put(`/workspace/${id}/cancel-subscription`, form);
			} catch (err) {
				console.log(err);
			}

			if (!response) {
				return false;
			}

			if (response.status === 201 || response.status === 200) {
				return response.data;
			} else {
				return false;
			}
		},
	},

	comments: {
		async get(type) {
			if (type === 'local') {
				let resources = await getStorage('um/comments');
				return resources;
			} else {
				let response = await http.get(`/comment`);
				let resources = [];
				if (response && response.data) {
					resources = response.data || [];
				}

				return resources;
			}
		},

		async fetchAndStore() {
			let resources = await this.get();
			await setStorage('um/comments', resources);
			return resources;
		},

		async add(resource) {
			let response = await http.post(`/comment`, resource);
			if (!response) {
				return null;
			}
			resource = response.data;
			return resource;
		},

		async update(resource) {
			let response = null;

			if (resource.ads) {
				delete resource.ads;
			}
			try {
				response = await http.put(`/comment/${resource.id}`, resource);
			} catch (err) {
				console.log(err);
			}
			if (!response) {
				return null;
			}
			resource = response.data;
			return resource;
		},

		async delete(id) {
			let response = null;
			try {
				response = await http.delete(`/comment/${id}`);
			} catch (err) {
				console.log(err);
			}
			if (response.status === 201 || response.status === 200) {
				return true;
			} else {
				return false;
			}
		},

		async getAdComments(adId) {
			let response = null;
			try {
				response = await http.get(`/comment/ad/${adId}`);
			} catch (err) {
				console.log(err);
			}

			return response.data || [];
		},
	},

	boards: {
		async verifyShare(boardId) {
			let form = {
				boardId,
			};
			try {
				let res = await http.post(`/board/verify-share`, form);
				return res.data || null;
			} catch (err) {
				throw err;
			}
		},
		async importShare(boardId) {
			let form = {
				boardId,
			};
			try {
				let res = await http.post(`/board/import-share`, form);
				return res.data || null;
			} catch (err) {
				throw err;
			}
		},
		async get(type) {
			if (type === 'local') {
				let resources = await getStorage('um/boards');
				return resources;
			} else {
				let response = await http.get(`/board`);
				let resources = [];
				if (response && response.data) {
					resources = response.data || [];
				}

				return resources;
			}
		},

		async fetchAndStore() {
			let resources = await this.get();
			await setStorage('um/boards', resources);
			return resources;
		},

		async add(resource) {
			let response = await http.post(`/board`, resource);
			if (!response) {
				return null;
			}
			resource = response.data;
			return resource;
		},

		async update(resource) {
			let response = null;

			if (resource.ads) {
				delete resource.ads;
			}
			try {
				response = await http.put(`/board/${resource.id}`, resource);
			} catch (err) {
				console.log(err);
			}
			if (!response) {
				return null;
			}
			resource = response.data;
			return resource;
		},

		async delete(id) {
			let response = null;
			try {
				response = await http.delete(`/board/${id}`);
			} catch (err) {
				console.log(err);
			}
			if (response.status === 201 || response.status === 200) {
				return true;
			} else {
				return false;
			}
		},

		async setOrder(resource) {
			let response = null;
			try {
				response = await http.put(`/board/order`, resource);
			} catch (err) {
				console.log(err);
			}
			if (response.status === 201 || response.status === 200) {
				return true;
			} else {
				return false;
			}
		},
	},

	brands: {
		async getOne(slug) {
			let response = await http.get(`/brand/${slug}`);

			let resources = null;
			if (response && response.data) {
				resources = response.data;
			}

			return resources;
		},
		async add(resource) {
			let response = null;
			try {
				response = await http.post(`/brand`, resource);
			} catch (err) {
				throw err;
			}

			if (response.status === 201 || response.status === 200) {
				return response.data;
			} else {
				return false;
			}
		},

		async removeTracker(trackerId) {
			let response = null;
			try {
				response = await http.delete(`/brand/tracker/${trackerId}`);
			} catch (err) {
				throw err;
			}

			if (response.status === 201 || response.status === 200) {
				return response.data;
			} else {
				return false;
			}
		},
	},

	ads: {
		async generateTranscript(form) {
			let response = null;

			try {
				response = await http.post(`/block/generate-transcript`, form);
			} catch (err) {
				if (err && err.response && err.response.data) {
					throw err.response.data;
				} else {
					throw [];
				}
			}
			if (!response) {
				return null;
			}
			return response.data;
		},

		async updateBrand(resource) {
			let response = null;

			try {
				response = await http.put(`/block/brand/${resource.id}`, resource);
			} catch (err) {
				if (err && err.response && err.response.data) {
					throw err.response.data;
				} else {
					throw [];
				}
			}
			if (!response) {
				return null;
			}
			resource = response.data;
			return resource;
		},
		async getAdIds() {
			let response = null;
			try {
				response = await http.get(`/block/ad-ids`);
			} catch (err) {
				throw err;
			}
			if (response.status === 200) {
				return response.data;
			} else {
				return false;
			}
		},
		async doAction(id, action, data = null) {
			let response = null;
			let form = {};
			if (data) {
				form.data = data;
			}
			try {
				response = await http.post(`/block/action/${action}/id/${id}`, form);
			} catch (err) {
				throw err;
			}
			if (response.status === 201) {
				return response.data;
			} else {
				return false;
			}
		},

		async getOne(adId) {
			let response = await http.get(`/block/ad/${adId}`);

			let resources = null;
			if (response && response.data) {
				resources = response.data;
			}

			return resources;
		},

		async get() {
			let response = await http.get(`/block`);

			let resources = [];
			if (response && response.data) {
				resources = response.data || [];
			}

			return resources;
		},
		async getAll() {
			let response = await http.get(`/block/all`);

			let resources = [];
			if (response && response.data) {
				resources = response.data || [];
			}

			return resources;
		},
		async set(ads) {
			return await storage.set('ads', ads);
		},

		async update(resource) {
			let response = null;

			try {
				response = await http.put(`/block/${resource.id}`, resource);
			} catch (err) {
				if (err && err.response && err.response.data) {
					throw err.response.data;
				} else {
					throw [];
				}
			}
			if (!response) {
				return null;
			}
			resource = response.data;
			return resource;
		},

		async add(resource) {
			let response = await http.post(`/block/ad`, resource).catch((err) => {
				throw err;
			});

			return response;
		},

		async delete(blockId) {
			let response = await http.delete(`/block/${blockId}`);
			return response.data;
			let ads = await this.get();
			let index = null;
			let condition = false;
			for (let i = 0; i < ads.length; i++) {
				if (blockId === ads[i].id) {
					index = i;
					condition = true;
					break;
				}
			}
			if (condition) {
				ads.splice(index, 1);
			}
			await this.set(ads);
			return condition;
		},
	},

	transactions: {
		async run(arr) {
			return await http.post('/block/transaction', arr);
		},
	},

	users: {
		async saveOnboardingAd() {
			try {
				let res = await http.post(`/user/save-onboarding-ad`);
				return res.data;
			} catch (err) {
				throw err;
			}
		},

		async pie() {
			let response = await http.get('/user/pie').catch((err) => {
				console.log(err);
			});

			return response.data || null;
		},

		async portal() {
			let response = null;
			try {
				response = await http.get('/user/portal');
			} catch (err) {
				throw err.response.data || [];
			}

			return response.data;
		},

		async signup(form) {
			let response = null;
			try {
				response = await http.post('/user/signup', form);
			} catch (err) {
				throw err.response.data || [];
			}

			return response.data;
		},

		async acceptInvite(form) {
			let response = null;
			try {
				response = await http.post(`/user/accept-invite`, form);
			} catch (err) {
				throw err.response.data || [];
			}

			return response.data;
		},

		async update(form) {
			let id = form.id;
			let response = null;
			try {
				response = await http.put(`/user/${id}`, form);
			} catch (err) {
				throw err.response.data || [];
			}

			return response.data;
		},

		async login(form) {
			let response = null;
			try {
				response = await http.post('/user/login', form);
			} catch (err) {
				if (err && err.response && err.response.data) {
					throw err.response.data;
				} else {
					throw [];
				}
			}
			let data = (response && response.data) || null;
			return data;
		},

		async headlessLogin() {
			let response = await http.post('/user/headless-login', {});
			let data = null;
			if (response && response.data) {
				data = response.data;
			}
			return data;
		},

		async resetPassword(form) {
			let response = null;
			try {
				response = await http.post('/user/reset-password', form);
			} catch (err) {
				if (err && err.response && err.response.data) {
					throw err.response.data;
				} else {
					throw [];
				}
			}
			let data = true;
			return data;
		},

		async updatePassword(form) {
			let response = null;
			try {
				response = await http.post('/user/update-password', form);
			} catch (err) {
				if (err && err.response && err.response.data) {
					throw err.response.data;
				} else {
					throw [];
				}
			}
			let data = (response && response.data) || null;
			return data;
		},

		async updateUserPassword(form) {
			let response = null;
			try {
				response = await http.post('/user/update-user-password', form);
			} catch (err) {
				if (err && err.response && err.response.data) {
					throw err.response.data;
				} else {
					throw [];
				}
			}
			let data = (response && response.data) || null;
			return data;
		},

		async getChromeStorage(key) {
			return new Promise((resolve, reject) => {
				chrome.storage.local.get([`um/${key}`], function (data) {
					resolve(data[`um/${key}`]);
				});
			});
		},

		async headlessChromeLogin() {
			let user = await this.getChromeStorage('user');

			if (user) {
				return user;
			}

			// if no user
			let token = await this.getChromeStorage('token');

			if (!token) {
				return null;
			}

			httpTools.setHeader('Authorization', `Bearer ${token}`);

			user = await this.headlessLogin();

			return user;
		},

		async logout() {
			let response = await http.post('/user/logout', {});
			if (response && response.status === 200) {
				await this.saveToken('');
				return true;
			} else {
				return false;
			}
		},

		async saveToken(token) {
			storage.set('token', token);
		},

		async getToken(mode) {
			if (mode === 'EXTENSION') {
				return new Promise((resolve, reject) => {
					let key = 'um/token';
					if (!chrome.storage) {
						resolve(null);
					} else {
						chrome.storage.local.get([key], (data) => {
							data = data[key] || null;
							resolve(data);
						});
					}
				});
			} else {
				return await storage.get('token');
			}
		},

		async upgrade(plan) {
			let body = {
				plan: plan,
			};
			let response = await http.post('/user/checkout', body);

			return response.data;
		},

		async buy(form) {
			let body = {
				quantity: form.quantity,
				plan: form.plan,
			};
			let response = await http.post('/user/checkout', body);

			return response.data;
		},

		async delete(id) {
			return await http.delete(`/user/${id}`);
		},

		async stats() {
			return await http.get('/user/stats');
		},
	},

	app: {
		async chatSecret() {
			let response = await http.get('/api/chat-secret');

			if (response.data) {
				return response.data;
			}

			return null;
		},
		async getSessionId() {
			return await storage.get('sessionId');
		},
		async setSessionId(sessionId) {
			return await storage.set('sessionId', sessionId);
		},

		async get() {
			let app = (await storage.get('app')) || {};
			return app;
		},

		async update(key, value) {
			let app = await this.get();
			app[key] = value;
			await storage.set('app', app);
		},

		async updateOptions(data) {
			await storage.set('app', data);
		},

		async getHelps() {
			let helps = await http.get('/api/helps');

			helps = helps.data || [];

			helps = helps.filter((article) => {
				if (article.status === 'draft') {
					return false;
				}
				return true;
			});

			return helps;
		},
	},
};

export default api;
