<template>
	<div class="c-brand-slug-stats">
		<article>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="48"
				height="48"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
				class="lucide lucide-activity"
			>
				<path d="M22 12h-4l-3 9L9 3l-3 9H2" />
			</svg>
			<span>
				{{ averageActiveDays }}
			</span>
			<p>Average ad running days</p>
		</article>
		<!-- <article>
			<svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M7.92308 6H18M18 6V16.0769M18 6L6 18" stroke-width="1.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
			</svg>

			<span>
				{{ insight.totalAds }}
			</span>
			<p>Total ads</p>
		</article>
		<article>
			<svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M7.92308 6H18M18 6V16.0769M18 6L6 18" stroke-width="1.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
			</svg>

			<span>
				{{ insight.totalAds }}
			</span>
			<p>Active ads</p>
		</article> -->
	</div>
</template>

<script>
export default {
	props: {
		insight: {},
	},

	computed: {
		averageActiveDays: function () {
			let days = this.insight.averageActiveDays || 0;

			if (isNaN(days)) {
				days = `NA`;
			}

			return days;
		},
	},

	methods: {
		calculatePercentage: function (param) {
			let data = this.insight.assetTypes;
			// Calculate total count
			const totalCount = Object.values(data).reduce((total, count) => total + count, 0);

			// Calculate percentages
			const percentages = {};
			for (const key in data) {
				if (Object.hasOwnProperty.call(data, key)) {
					percentages[key] = ((data[key] / totalCount) * 100).toFixed(2);
				}
			}

			for (const key in percentages) {
				if (isNaN(percentages[key])) {
					percentages[key] = 0;
				}
			}

			if (param) {
				return percentages[param];
			}

			return percentages;
		},
		computedStyle: function (param) {
			let percentage = this.calculatePercentage(param);

			return {
				width: `${percentage}%`,
			};
		},
	},
};
</script>

<style lang="scss">
.c-brand-slug-stats {
	margin-top: var(--margin-lg);
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;

	article {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-top: var(--margin-lg);

		span {
			display: inline-block;
			padding: var(--margin) 0;
			font-weight: 450;
			font-feature-settings: 'case' 0, 'dlig' 0, 'frac' 0, 'dnom' 0, 'numr' 0, 'subs' 0, 'sups' 0, 'tnum', 'zero' 0, 'ss01', 'ss02' 0, 'ss03' 0, 'ss04', 'ss05' 0, 'ss06' 0, 'ss07' 0, 'ss08' 0,
				'cv01' 0, 'cv02' 0, 'cv03' 0, 'cv04' 0, 'cv05' 0, 'cv06' 0, 'cv07' 0, 'cv08' 0, 'cv09' 0, 'cv10' 0, 'cv11' 0, 'cv12' 0, 'cv13' 0, 'cpsp' 0, 'c2sc' 0, 'salt' 0, 'aalt' 0, 'calt', 'ccmp', 'locl',
				'kern';
			font-size: var(--font-size-lg);
		}

		p {
			opacity: 0.75;
		}
	}
}
</style>
