<template>
	<div class="c-popup-tags">
		<Popup :active="active" :target="target" @onClose="onClose">
			<!-- <span class="c-popup-tags__reset" v-if="selectedTags.length > 0" @click="onResetTags">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M5 5L12 12M19 19L12 12M12 12L19 5L5 19" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
				Reset tags</span
			>
			<InputCheckbox v-model="selectedTags" :options="computedTags"></InputCheckbox> -->
			<div class="c-popup-tags__inner">
				<span :class="['c-popup-tags__item', { active: selectedTag === tag.slug }]" v-for="(tag, i) in computedTags" :key="i" @click="toggleSlug(tag.slug)">
					{{ tag.name }}
				</span>
			</div>
		</Popup>
	</div>
</template>

<script>
import Popup from './index';
import InputCheckbox from '@swipekit/components/form/input-checkbox';

export default {
	components: {
		Popup,
		InputCheckbox,
	},

	data: function () {
		return {
			selectedTag: '',
		};
	},

	props: {
		active: {
			type: Boolean,
			default: false,
		},
		target: {},
	},

	watch: {
		selectedTags: function () {
			this.$emit('input', this.selectedTags);
		},
	},

	computed: {
		workspace: function () {
			return this.$store.getters['workspaces/all'];
		},
		computedTags: function () {
			let workspace = this.workspace;
			let tags = workspace.tags2 || [];

			// tags = tags.map((tag) => {
			//   let str = tag;
			//   if (typeof str !== "string") {
			//     str = tag.text;
			//   }
			//   return {
			//     name: str,
			//     slug: str,
			//   };
			// });

			let newTags = [];

			for (let i = 0; i < tags.length; i++) {
				if (typeof tags[i] !== 'string') {
					continue;
				}

				newTags.push({
					name: tags[i],
					slug: tags[i],
				});
			}

			//order them
			newTags.sort(function (a, b) {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			});

			return newTags;
		},
	},

	methods: {
		toggleSlug: function (slug) {
			if (!this.selectedTag) {
				this.selectedTag = slug;
				this.$emit('input', slug);
			} else if (this.selectedTag && this.selectedTag !== slug) {
				this.selectedTag = slug;
				this.$emit('input', slug);
			} else {
				this.selectedTag = '';
				this.$emit('input', '');
			}
		},
		onClose: function () {
			this.$emit('onClose');
		},
		onResetTags: function () {
			this.selectedTags = [];
			this.$emit('resetTags');
		},
	},
};
</script>

<style lang="scss">
.c-popup-tags {
	.c-popup {
		width: 300px;
		background: var(--color-bg-1);

		&__arrow {
			color: var(--color-bg-1);
		}
	}
	.c-input-checkbox {
		padding-bottom: 0;
		.c-input-checkbox__item {
			margin-bottom: 0;

			&:not(:last-child) {
				padding-bottom: var(--margin-sm);
			}
		}
	}

	&__item {
		display: inline-block;
		position: relative;
		margin-right: 4px;
		margin-bottom: 4px;
		background-color: var(--color-bg-4);
		padding: 5.5px 8px;
		padding-right: 14px;
		font-size: var(--font-size-sm);
		line-height: 1;
		border-radius: var(--border-radius);
		user-select: none;
		cursor: pointer;
		transition: all var(--transition-time-sm) ease-out;

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: calc(50% - 2px);
			right: 6px;
			width: 4px;
			height: 4px;
			background-color: currentColor;
			border-radius: 2px;
		}

		&:hover,
		&:focus,
		&.active {
			outline: none;
			background-color: var(--color-primary);
			color: var(--color-button-font);
		}
	}
	&__inner {
		padding: var(--margin) var(--margin) 0 var(--margin);
	}
	&__close {
		position: absolute;
		top: 12px;
		right: var(--margin-lg);
		opacity: 0.75;
		cursor: pointer;

		&:hover,
		&:active {
			opacity: 1;
		}
	}

	&__reset {
		display: flex;
		padding: var(--margin-lg);
		border-bottom: var(--color-bg-5) solid 1px;
		cursor: pointer;
		user-select: none;

		svg {
			color: var(--color-danger);
			margin-right: var(--margin-sm);
		}
	}
}
</style>
