<template>
	<div class="c-brand-slug-breakdown">
		<strong> Creative breakdown </strong>
		<div>
			<span class="bg" :style="computedStyle('video')"></span>
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M5 4H19M11 11.5V15.5L13.5 13.5L11 11.5ZM4 20H20C20.5523 20 21 19.5523 21 19V8C21 7.44772 20.5523 7 20 7H4C3.44772 7 3 7.44772 3 8V19C3 19.5523 3.44772 20 4 20Z"
					stroke="currentColor"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>

			<span> Video </span>
			<span> {{ insight.assetTypes.video }} ({{ calculatePercentage('video') }}%) </span>
		</div>
		<div>
			<span class="bg" :style="computedStyle('image')"></span>
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M3 4C3 3.44772 3.44772 3 4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4ZM5 5V13.5858L7.29289 11.2929C7.68342 10.9024 8.31658 10.9024 8.70711 11.2929L16.4142 19H19V5H5ZM13.5858 19L8 13.4142L5 16.4142V19H13.5858Z"
					fill="currentColor"
				/>
				<path
					d="M13.5745 8.35093L14.0528 7.39443C14.237 7.0259 14.763 7.0259 14.9472 7.39443L15.4255 8.35093C15.4738 8.44769 15.5523 8.52615 15.6491 8.57454L16.6056 9.05279C16.9741 9.23705 16.9741 9.76295 16.6056 9.94721L15.6491 10.4255C15.5523 10.4738 15.4738 10.5523 15.4255 10.6491L14.9472 11.6056C14.763 11.9741 14.237 11.9741 14.0528 11.6056L13.5745 10.6491C13.5262 10.5523 13.4477 10.4738 13.3509 10.4255L12.3944 9.94721C12.0259 9.76295 12.0259 9.23705 12.3944 9.05279L13.3509 8.57454C13.4477 8.52615 13.5262 8.44769 13.5745 8.35093Z"
					fill="currentColor"
				/>
			</svg>

			<span> Statics </span>
			<span> {{ insight.assetTypes.image }} ({{ calculatePercentage('image') }}%) </span>
		</div>
		<div>
			<span class="bg" :style="computedStyle('carousel')"></span>
			<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M7 18H4C3.44772 18 3 17.5523 3 17V7C3 6.44772 3.44772 6 4 6H7" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<path
					d="M16 4H8C7.44772 4 7 4.44772 7 5V19C7 19.5523 7.44772 20 8 20H16C16.5523 20 17 19.5523 17 19V5C17 4.44772 16.5523 4 16 4Z"
					stroke="currentColor"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path d="M17 6H20C20.5523 6 21 6.44772 21 7V17C21 17.5523 20.5523 18 20 18H17" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
			</svg>

			<span> Carousel </span>
			<span> {{ insight.assetTypes.carousel }} ({{ calculatePercentage('carousel') }}%) </span>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		insight: {},
	},

	methods: {
		calculatePercentage: function (param) {
			let data = this.insight.assetTypes;
			// Calculate total count
			const totalCount = Object.values(data).reduce((total, count) => total + count, 0);

			// Calculate percentages
			const percentages = {};
			for (const key in data) {
				if (Object.hasOwnProperty.call(data, key)) {
					percentages[key] = ((data[key] / totalCount) * 100).toFixed(2);
				}
			}

			for (const key in percentages) {
				if (isNaN(percentages[key])) {
					percentages[key] = 0;
				}
			}

			if (param) {
				return percentages[param];
			}

			return percentages;
		},
		computedStyle: function (param) {
			let percentage = this.calculatePercentage(param);

			return {
				width: `${percentage}%`,
			};
		},
	},
};
</script>

<style lang="scss">
.c-brand-slug-breakdown {
	margin-top: var(--margin-lg);

	strong {
		display: block;
		margin-bottom: var(--margin);
	}

	> div {
		position: relative;
		display: grid;
		grid-template-columns: 30px 1fr 128px;
		align-items: center;
		padding: var(--margin-sm);
		border-radius: 4px;
		border: hsla(var(--p), 78%, 62%, 0.15) solid 1px;
		font-size: var(--font-size-sm);
		font-feature-settings: 'case' 0, 'dlig' 0, 'frac' 0, 'dnom' 0, 'numr' 0, 'subs' 0, 'sups' 0, 'tnum', 'zero' 0, 'ss01', 'ss02' 0, 'ss03' 0, 'ss04', 'ss05' 0, 'ss06' 0, 'ss07' 0, 'ss08' 0, 'cv01' 0,
			'cv02' 0, 'cv03' 0, 'cv04' 0, 'cv05' 0, 'cv06' 0, 'cv07' 0, 'cv08' 0, 'cv09' 0, 'cv10' 0, 'cv11' 0, 'cv12' 0, 'cv13' 0, 'cpsp' 0, 'c2sc' 0, 'salt' 0, 'aalt' 0, 'calt', 'ccmp', 'locl', 'kern';

		> span {
			font-weight: 500;
		}

		&:not(:last-child) {
			margin-bottom: var(--margin);
		}

		> .bg {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			background-color: var(--color-primary);
			opacity: 0.075;
			border-radius: 4px;
		}

		span:last-child {
			text-align: right;
		}
	}
}
</style>
