<template>
	<form class="c-form-block-create" @submit.prevent="onSubmit">
		<Form ref="form" :schema="schema" v-model="form" @status="saveStatus" buttonText="Track brand" :processing="processing" :errors="errors"></Form>
	</form>
</template>

<script>
import Form from './index.vue';

const schema = {
	url: {
		component: 'InputText',
		label: 'Brand link',
		placeholder: 'https://www.facebook.com/ads/library/?id=421247629844588',
		properties: {
			columnSize: 6,
		},
	},
};

export default {
	components: {
		Form,
	},

	data: function () {
		return {
			processing: false,
			schema: schema,
			form: {
				url: '',
			},
			errors: [],
			status: null,
		};
	},

	methods: {
		parseFacebookAdsUrl: function (url) {
			try {
				// Create a URL object to validate the URL
				const parsedUrl = new URL(url);

				// Check if the host and pathname match the required values
				if (parsedUrl.host === 'www.facebook.com' && parsedUrl.pathname === '/ads/library/') {
					// Get the value of the view_all_page_id parameter
					const pageId = parsedUrl.searchParams.get('view_all_page_id');

					// Check if the view_all_page_id has a valid value
					if (pageId && !isNaN(pageId)) {
						return pageId; // Return the value of view_all_page_id
					}
				}
			} catch (error) {
				// Invalid URL will throw an error, which we catch
				console.error('Invalid URL:', error);
				return null;
			}

			// Return null if any conditions are not met
			return null;
		},
		saveStatus: function (saveStatus) {
			this.status = saveStatus;
		},
		async onSubmit() {
			this.$refs.form.validate();

			if (this.status.invalid) {
				return;
			}

			this.processing = true;

			const brandId = this.parseFacebookAdsUrl(this.form.url);

			if (!brandId) {
				this.errors = [
					{
						message: 'Not a valid facebook ad library url. Check the example.',
					},
				];
				this.processing = false;
				return;
			}

			let form = this.form;

			let obj = {
				...form,
			};

			let res = await this.$store.dispatch('brands/add', obj).catch((err) => {
				if (err && err.response && err.response.status === 400) {
					this.errors = [
						{
							message: `You cannot add any more trackers for your account.`,
						},
					];
				} else {
					this.errors = [
						{
							message: err.message || 'Something went wrong.',
						},
					];
				}
			});

			if (res) {
				this.$emit('onSubmit', res);
			}

			this.processing = false;
		},
	},
};
</script>

<style></style>
