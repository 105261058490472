<template>
	<div class="c-brand-faqs">
		<Accordion>
			<template v-slot:title>
				<h4>I started tracking a brand. What data is it showing me?</h4>
			</template>

			<template v-slot:body>
				<div>
					<p>When you initially start tracking a brand, it will calculate insights based on existing ads of the brand.</p>
					<p>It will also gather new ads from this point onwards.</p>
					<p>Over time, it will track brands and scrape any ads available on the Facebook ad library for said brand.</p>
					<p>These ads will be available under the Brand Tracker's ads page.</p>
					<p>It will also calculate insights such as:</p>
					<ul>
						<li>Common landing pages</li>
						<li>Format breakdowns</li>
						<li>Common word occurences</li>
					</ul>
					<p>And much more.</p>
				</div>
			</template>
		</Accordion>

		<Accordion>
			<template v-slot:title>
				<h4>How can I track more than 2 brands?</h4>
			</template>

			<template v-slot:body>
				<div>
					<p>Brand Tracker is in beta period and as such, we have limited tracking to only 2 brands. Over time we will increase this limit for paying customers.</p>
				</div>
			</template>
		</Accordion>

		<Accordion>
			<template v-slot:title>
				<h4>Are the ads scraped by the tracker shown in multiplayer</h4>
			</template>

			<template v-slot:body>
				<div>
					<p>No the scraped ads aren't shown in Swipekit Multiplayer.</p>
				</div>
			</template>
		</Accordion>

		<Accordion>
			<template v-slot:title>
				<h4>If I track a brand, will the scraped ads be automatically saved in my library?</h4>
			</template>

			<template v-slot:body>
				<div>
					<p>The scraped ads will be available for save under the Brand Tracker's ads page. But they won't be saved in your library by default.</p>
					<p>Only when you click the save button on the ad inside the ads page will the ad be saved in your library.</p>
					<p>Saving an ad in your library guarantees that the ad will be available in your account forever, unless you remove it.</p>
				</div>
			</template>
		</Accordion>

		<Accordion>
			<template v-slot:title>
				<h4>How long will scraped ads be available?</h4>
			</template>

			<template v-slot:body>
				<div>
					<p>The scraped ads are available for around 90 days.</p>
					<p>Beyond that, we might remove the scraped ads.</p>
					<p>If you wish to save the ad permanently, you need to click the save button on the ad, which will save it to your library.</p>
				</div>
			</template>
		</Accordion>

		<Accordion>
			<template v-slot:title>
				<h4>I have a feature request? Do you take feedback?</h4>
			</template>

			<template v-slot:body>
				<div>
					<p>Absolutely! We're always open to feedback. Feel free to open a chat via the chat widget in the bottom right corner of the app.</p>
				</div>
			</template>
		</Accordion>
	</div>
</template>

<script>
import Accordion from '@swipekit/components/utils/accordion';
export default {
	components: {
		Accordion,
	},
};
</script>

<style lang="scss">
.c-brand-faqs {
	position: relative;
	width: calc(var(--header-width) + 360px);
	margin: 0 auto;
	padding-top: var(--spacer);
	margin-top: var(--spacer);

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: var(--color-bg-4);
	}
}
</style>
