<template>
	<div ref="chart" class="c-chart-points c-chart-bar" :key="flip">
		<div class="c-chart-bar__popup" v-if="popupActive" :style="popupStyle">
			<span v-if="popupDatum">{{ popupDatum.topText }}</span>
			<span v-if="popupDatum">{{ popupDatum.bottomText }}</span>
		</div>
		<div class="bar" v-for="(item, i) in data" :key="i" :data-key="i" :data-count="item.y" :data-date="item.date">
			<span :style="computeStyle(item)"></span>
		</div>
		<!-- <div class="c-chart-bar__ticks">
			<div :class="['tick', `t-${i}`]" v-for="(item, i) in data" :key="i" data-key="i">
				{{ item.date }}
			</div>
		</div> -->
	</div>
</template>

<script>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export default {
	data: function () {
		return {
			popupActive: false,
			popupDatum: null,
			popupX: 0,
			popupY: 0,

			flip: false,
		};
	},

	props: {
		data: {
			default: function () {
				return [];
			},
		},
	},

	watch: {
		data: function () {
			this.removeEventListeners();
			this.$nextTick(() => {
				this.flip = !this.flip;

				this.$nextTick(() => {
					this.addEventListeners();
				});
			});
		},
	},

	computed: {
		popupStyle: function () {
			let top = this.popupY || 0;
			let left = this.popupX || 0;

			let chart = this.$refs.chart;

			let chartRect = chart.getBoundingClientRect();

			left = left - chartRect.left;

			return {
				//top: `${top}px`,
				left: `${left}px`,
			};
		},
		width: function () {
			let count = this.data.length;
			// Define the rate at which y decreases as x increases
			const decreaseRate = 2.8; // Adjust this value as needed
			const minimumY = 4;
			const maximumY = 60;

			// Calculate y based on the decreaseRate
			let y = 100 - decreaseRate * count;

			// Ensure y stays within the specified range
			y = Math.max(minimumY, Math.min(maximumY, y));

			return y;
		},
		maxY: function () {
			let maxYValue = this.data.reduce((max, obj) => (obj.y > max ? obj.y : max), -Infinity);

			return maxYValue;
		},
	},

	methods: {
		mapValueToRange(value, min1, max1, min2, max2) {
			return min2 + ((value - min1) * (max2 - min2)) / (max1 - min1);
		},
		computeStyle: function (item) {
			let diff = this.mapValueToRange(item.y, 0, this.maxY, 0, 100);

			return {
				//width: `${this.width}px`,
				height: `${diff}%`,
			};
		},
		onMouseMove: function (e) {
			// if (e.target.className !== 'bar') {
			// 	return;
			// }
			let bar = e.target.closest('.bar');
			if (!bar) {
				return;
			}
			let boundingBox = e.target.getBoundingClientRect();

			let width = boundingBox.width;

			this.popupX = boundingBox.x + width / 2;
			this.popupY = boundingBox.top;
			let date = dayjs(bar.dataset.date, `YYYY-MM-DD`);
			date = date.format('ddd D MMM');
			let s = 's';
			if (bar.dataset.count == 1) {
				s = '';
			}
			this.popupDatum = {
				topText: `${bar.dataset.count} ad${s} published`,
				bottomText: `${date}`,
			};
			this.popupActive = true;
		},
		onMouseOut: function () {
			this.popupActive = false;
		},
		addEventListeners: function () {
			let el = this.$el;
			el.addEventListener('mousemove', this.onMouseMove);
			el.addEventListener('mouseout', this.onMouseOut);
		},
		removeEventListeners: function () {
			let el = this.$el;
			el.removeEventListener('mousemove', this.onMouseMove);
			el.removeEventListener('mouseout', this.onMouseOut);
		},
	},

	mounted: function () {
		this.addEventListeners();
	},

	beforeDestroy: function () {
		this.removeEventListeners();
	},
};
</script>

<style lang="scss">
.c-chart-bar {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	border-bottom: var(--chart-color-secondary) solid 2px;

	&__popup {
		position: absolute;
		z-index: 2;
		top: -44px;
		left: 0;
		width: 130px;
		padding: var(--margin);
		font-size: var(--font-size-xs);
		transform: translateX(-50%);
		background-color: var(--color-bg-3);
		border-radius: var(--border-radius);
		box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(9, 9, 11, 0.06) 0px 0px 0px 1px, rgba(9, 9, 11, 0.06) 0px 1px 2px -1px, rgba(9, 9, 11, 0.02) 0px 2px 4px 0px;

		span {
			display: block;
		}
	}

	&__ticks {
		position: absolute;
		bottom: -4px;
		left: 0;
		width: 100%;
		height: 6px;

		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		.tick {
			height: 100%;
			width: 1px;
			background-color: var(--color-primary);
			opacity: 0;
		}
	}

	.bar {
		display: grid;
		//grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
		grid-column-gap: 2px;
		align-items: end;
		position: relative;
		padding: 0 var(--margin-sm);

		height: 100%;
		width: 100%;

		&:after {
			content: '';
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: var(--color-bg-4);
			opacity: 0;
		}

		> span {
			position: relative;
			z-index: 1;
			display: block;
			width: 100%;
			background: linear-gradient(0deg, var(--chart-color) 0%, var(--chart-color-light) 100%);
			border-top-left-radius: 2px;
			border-top-right-radius: 2px;

			&:last-child {
				background: linear-gradient(0deg, var(--chart-color-secondary) 0%, var(--chart-color-secondary-light) 100%);
			}
		}

		&:hover {
			&:after {
				opacity: 1;
			}
		}
	}

	@media screen and (max-width: 576px) {
		> div {
			padding: 0 var(--margin-sm);
		}
	}
}
</style>
