import { http } from './http.js';

async function filter(params, path = 'filter-block') {
	let blocks = [];

	let url = `/api/${path}`;

	const config = {
		params: params,
	};

	let response = await http.get(url, config);

	if (response.data) {
		blocks = response.data;
	}

	return blocks;
}

export default filter;
