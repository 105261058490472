import api from '@swipekit/lib/api';

let store = {
	namespaced: true,
	state: {
		init: false,

		helps: [],

		options: {
			onboarded: false,
			gridSize: 2,
		},

		showOnboarding: false,

		showSettings: false,

		showSidebar: false,

		extensionHelperId: null,
		emailActivationId: null,
		shareId: false,
		shareAdId: false,
		shareAd: null, // contains the actual ad data
		rewardTrialExtensionId: false,
		addBrand: false,

		boardSaveId: false,

		processing: false,

		refreshFilters: false,

		modals: {
			multiplayer: false,
			library: false,
		},

		multiplayerCategories: [],

		// if true, signups get extra 10 days of usage
		trialExtension: false,

		// The person who is referring
		referralPartner: '',
	},
	getters: {
		init: function (state) {
			return state.init;
		},
		windowActive: function (state) {
			return state.windowActive;
		},
		showSettings: function (state) {
			return state.showSettings;
		},
		showOnboarding: function (state) {
			return state.showOnboarding;
		},
		showSidebar: function (state) {
			return state.showSidebar;
		},
		options: function (state) {
			return state.options;
		},
		extensionHelperId: function (state) {
			return state.extensionHelperId;
		},
		shareId: function (state) {
			return state.shareId;
		},
		shareAdId: function (state) {
			return state.shareAdId;
		},
		shareAd: function (state) {
			return state.shareAd;
		},
		rewardTrialExtensionId: function (state) {
			return state.rewardTrialExtensionId;
		},
		processing: function (state) {
			return state.processing;
		},
		refreshFilters: function (state) {
			return state.refreshFilters;
		},
		multiplayerCategories: function (state) {
			return state.multiplayerCategories;
		},
		modals: function (state) {
			return state.modals;
		},
		trialExtension: function (state) {
			return state.trialExtension;
		},
		referralPartner: function (state) {
			return state.referralPartner;
		},
		boardSaveId: function (state) {
			return state.boardSaveId;
		},
		addBrand: function (state) {
			return state.addBrand;
		},
	},
	mutations: {
		SET_OPTIONS: function (state, options) {
			state.options = options;
		},
		SET_OPTION: function (state, data) {
			let key = data.key;
			let value = data.value;

			state.options[key] = value;
		},
		SET_WINDOW_ACTIVE: function (state, condition) {
			state.windowActive = condition;
		},
		SET_SHOW_SETTINGS: (state, condition) => {
			state.showSettings = condition;
		},
		SET_SHOW_ONBOARDING: (state, condition) => {
			state.showOnboarding = condition;
		},
		SET_INIT: function (state) {
			state.init = true;
		},
		SET_GRIDSIZE: (state, gridSize) => {
			state.options.gridSize = gridSize;
		},
		SET_SHARE: (state, id) => {
			state.shareId = id;
		},
		SET_AD_SHARE: (state, adData) => {
			if (adData) {
				state.shareAdId = adData.id;
				state.shareAd = adData;
			} else {
				state.shareAdId = null;
				state.shareAd = null;
			}
		},
		TOGGLE_SIDEBAR: function (state, condition) {
			state.showSidebar = condition;
		},
	},
	actions: {
		async init(store) {
			let options = await api.app.get();

			let currentOptions = {
				...store.state.options,
				...options,
			};
			store.commit('SET_OPTIONS', currentOptions);
		},

		hideWindow: function (store) {
			store.commit('SET_WINDOW_ACTIVE', false);
		},

		toggleSettings: function (store, condition) {
			store.commit('SET_SHOW_SETTINGS', condition);
		},
		toggleOnboarding: function (store, condition) {
			store.commit('SET_SHOW_ONBOARDING', condition);
		},

		setInit: function (store) {
			store.commit('SET_INIT');
		},

		setGridSize: function (store, gridSize) {
			store.commit('SET_GRIDSIZE', gridSize);
		},

		showExtensionHelper: function (store, condition = false) {
			store.state.extensionHelperId = condition;
		},

		setRewardTrialExtension: function (store, condition) {
			store.state.rewardTrialExtensionId = condition;
		},

		setSidebar: function (store, condition) {
			store.commit('TOGGLE_SIDEBAR', condition);
		},

		toggleSidebar: function (store) {
			store.commit('TOGGLE_SIDEBAR', !store.state.showSidebar);
		},

		setProcessing: function (store, condition = false) {
			store.state.processing = condition;
		},

		refreshFilters: function (store) {
			store.state.refreshFilters = !store.state.refreshFilters;
		},

		toggleModal: function (store, type) {
			let keys = Object.keys(store.state.modals);

			if (!keys.includes(type)) {
				console.log(`MODAL ${type} not found!`);
				return;
			}

			let newVal = !store.state.modals[type];

			store.state.modals = {
				...store.state.modals,

				[type]: newVal,
			};
		},

		async setShare(store, id) {
			store.commit('SET_SHARE', id);
		},

		async setAdShare(store, adData) {
			store.commit('SET_AD_SHARE', adData);
		},

		async setAddBrand(store, condition) {
			store.state.addBrand = condition;
		},

		async sendContactForm(store, form) {
			return await api.api.doAction(form.id, form.action, form.payload);
		},

		async promptEmailActivation(store) {
			store.state.emailActivationId = 1;
		},

		async getHelps(store) {
			if (store.state.helps && store.state.helps.length > 0) {
				return store.state.helps;
			} else {
				const helps = await api.app.getHelps();
				store.state.helps = helps;

				return helps;
			}
		},

		async afterLogin(store, pie) {
			if (store.rootGetters['users/isAuth']) {
				if (!pie) {
				} else {
					await store.dispatch('consumePie', pie);
				}
			}
		},

		async consumePie(store, pie) {
			if (pie.multiplayerCategories && pie.multiplayerCategories.length) {
				store.state.multiplayerCategories = JSON.parse(JSON.stringify(pie.multiplayerCategories));

				delete pie.multiplayerCategories;
			}
		},

		async setReferral(store, ref) {
			let referrals = ['nivesh', 'paco', 'shash'];

			if (!referrals.includes(ref)) {
				return;
			}

			if (ref === 'nivesh') {
				store.state.referralPartner = 'Nivesh';
			}

			if (ref === 'shash') {
				store.state.referralPartner = 'Shash';
			}

			store.state.trialExtension = true;
		},

		async boardSave(store, boardId) {
			store.state.boardSaveId = boardId;
		},
	},
	modules: {},
};

export default store;
