<template>
	<div class="c-modal-board-save">
		<Modal v-model="options.active" @input="closeModal" :closable="true">
			<template v-if="!payload">
				<div class="c-spinner"></div>
			</template>
			<template v-if="payload">
				<div v-if="!payload.error">
					<h1>🎁</h1>
					<p class="article-text-lg">{{ payload.userName }} has made their board shareable!</p>
					<p>Save all ads in the "{{ payload.boardName }}" board into your Swipekit account.</p>

					<a class="btn btn-lg btn-primary" href="https://chrome.google.com/webstore/detail/ad-library-downloader/gojmmkhaiojimnnjhhilmhjmhdbdagod" target="_blank"> Save Ads </a>
				</div>
				<div v-else>
					<strong> Board couldn't be saved. </strong>
					<p>This might be because of:</p>
					<ul>
						<li>The board isn't shareable</li>
						<li>The board might have been taken down</li>
					</ul>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import Modal from './index.vue';

import { parseISO, format } from 'date-fns';

import BlockView from '@swipekit/components/block-view';

import api from '@swipekit/lib/api';

export default {
	components: {
		Modal,
		BlockView,
	},

	data: function () {
		return {
			trialLength: 10,

			payload: null,

			options: {
				name: 'modal-block-view',
				active: false,
			},
		};
	},

	watch: {
		boardSaveId: {
			handler: function () {
				if (this.boardSaveId) {
					this.options.active = true;
				} else {
					this.options.active = false;
				}

				this.loadPayload();
			},
			immediate: false,
		},
	},

	computed: {
		user: function () {
			return this.$store.getters['users/user'];
		},
		boardSaveId: function () {
			let boardId = this.$store.getters['app/boardSaveId'];

			return boardId;
		},
	},

	methods: {
		loadPayload: async function () {
			let payload = await api.boards.verifyShare(this.boardSaveId).catch((err) => {});

			if (!payload) {
				payload = {
					error: true,
				};
			} else {
				payload.error = false;
			}
			this.payload = payload;
		},
		closeModal: function () {
			this.$store.dispatch('app/boardSave', false);
			this.$emit('closeModal');
		},
	},

	mounted: function () {},
};
</script>

<style lang="scss">
.c-modal-board-save {
	.modal__content {
		padding: var(--spacer);
		padding-top: 0;

		text-align: center;

		> .c-spinner {
			position: absolute;
			top: calc(50% - 24px);
			left: calc(50% - 24px);
			width: 48px;
			height: 48px;
			border-width: 4px;
			border-color: #000;
			border-bottom-color: transparent;
		}

		.article-text-lg {
			font-weight: 500;

			strong {
				font-weight: 700;
			}
		}

		ul {
			max-width: 380px;
			margin: 0 auto;
			margin-top: var(--spacer-sm);
			text-align: left;
		}
	}
}
</style>
