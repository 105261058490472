<template>
	<div class="form-group c-input-button-choice">
		<button type="button" @click="onMainClick" :class="['btn', type, buttonClass, { disabled: disabled === true || processing === true }]">
			<span class="c-spinner" v-if="processing"></span>
			<span>
				{{ computedLabel }}
			</span>
			<div class="c-input-button-choice__container" v-if="clicked">
				<span class="btn-mini" @click.prevent="onClick"> Yes </span>
				<span class="divider">|</span>
				<span class="btn-mini" @click.prevent="onNegativeClick($event)"> No </span>
			</div>
		</button>
		<InputError v-if="hasError" :message="errorMessage"></InputError>
	</div>
</template>

<script>
import InputError from './input-error';
import slugify from '@swipekit/lib/slugify.js';

export default {
	components: {
		InputError,
	},

	data: function () {
		return {
			clicked: false,
		};
	},

	props: {
		label: {
			type: String,
			default: '',
		},
		validation: {},
		value: {},
		placeholder: {
			type: String,
			default: '',
		},
		hasError: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		processing: {
			type: Boolean,
			default: false,
		},
		buttonClass: {
			type: String,
			default: 'btn-primary',
		},
		type: {
			type: String,
			default: '',
		},
	},
	computed: {
		computedLabel: function () {
			if (this.clicked) {
				return 'Sure?';
			}
			return this.label;
		},
		slug: function () {
			return slugify(this.label);
		},
		// hasError: function() {
		//   if (
		//     this.validation &&
		//     this.validation.$dirty === true &&
		//     this.validation.$invalid === true
		//   ) {
		//     return true;
		//   } else {
		//     return false;
		//   }
		// },
		errorMessage: function () {
			if (!this.hasError) {
				return '';
			}
			return 'Please fill in this input';
		},
	},
	methods: {
		onMainClick: function () {
			if (this.disabled) {
				return;
			}
			this.clicked = true;
		},
		onChange: function (e) {
			if (this.disabled || this.processing) {
				return;
			}
			let value = e.target.value;
			this.$emit('input', value);
		},
		onNegativeClick: function (e) {
			e.preventDefault();
			if (e.stopPropagation) {
				e.stopPropagation();
			}
			this.clicked = false;
		},
		onClick: function (e) {
			return this.$emit('input');
		},
	},
};
</script>

<style lang="scss">
.c-input-button-choice {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: var(--grid);
	transition: all var(--transition-time) linear;

	button {
		flex-grow: 0;
	}
	.c-spinner {
		margin-right: var(--margin-lg);
	}

	&__container {
		font-size: var(--font-size-xs);
		font-weight: 500;
		padding: var(--margin);

		span {
			display: inline-block;
		}

		.btn-mini {
			padding: var(--margin-sm) var(--margin);
			background-color: var(--color-bg-1);
			color: var(--color-button-font-inverted);
			border-radius: var(--border-radius-full);

			&:hover {
				background-color: var(--color-bg-4);
				@include focus();
			}
		}

		.divider {
			margin: 0 var(--margin);
		}
	}
}
</style>
