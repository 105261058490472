<template>
	<form class="c-form-password" @submit.prevent="onSubmit">
		<Form :processing="processing" ref="form" :schema="schema" v-model="form" buttonText="Reset Password" @status="saveStatus" :errors="errors"></Form>
	</form>
</template>

<script>
import Form from './index.vue';

const schema = {
	password1: {
		component: 'InputPassword',
		label: 'Password',
		placeholder: '********',
		properties: {
			columnSize: 6,
		},
		validations: {
			required: {
				params: null,
				message: 'This field is required',
			},
			minLength: {
				params: 8,
				message: 'Password should be at least 8 characters',
			},
			maxLength: {
				params: 40,
				message: `That's a looong password. Make it short ya'll.`,
			},
		},
	},
	password2: {
		component: 'InputPassword',
		label: 'Enter your password again',
		placeholder: '********',
		properties: {
			columnSize: 6,
		},
		validations: {
			required: {
				params: null,
				message: 'This field is required',
			},
			minLength: {
				params: 8,
				message: 'Password should be at least 8 characters',
			},
			maxLength: {
				params: 40,
				message: `That's a looong password. Make it short ya'll.`,
			},
		},
	},
};
export default {
	components: {
		Form,
	},

	data: function () {
		return {
			schema: schema,
			processing: false,
			form: {
				resetPasswordToken: '',
				email: '',
				password: '',
			},
			errors: [],
			status: null,
		};
	},

	methods: {
		saveStatus: function (saveStatus) {
			this.status = saveStatus;
		},
		async onSubmit() {
			this.$refs.form.validate();

			if (this.status.invalid) {
				return;
			}

			this.processing = true;

			let form = this.form;

			if (form.password1 !== form.password2) {
				this.errors = [
					{
						message: 'Passwords must match',
					},
				];
				this.processing = false;
				return;
			}

			let res = null;

			try {
				res = await this.$store.dispatch('users/updatePassword', form);
			} catch (err) {
				console.log(err);
				if (err.length === 0) {
					err.push({
						message: 'Something went wrong, please send another reset password request.',
					});
				}
				this.errors = err;
				this.processing = false;
				return;
			}

			await this.$store.dispatch('message/onPasswordReset');

			this.$emit('onSubmit');

			setTimeout(() => {
				window.location.href = 'https://app.swipekit.app';
			}, 500);

			this.processing = false;
		},
	},

	mounted: function () {
		let query = this.$route.query || {};

		if (!query.resetpassword) {
			this.$router.push('/');
		}

		this.form.resetPasswordToken = query.resetpassword;
	},
};
</script>

<style></style>
