<template>
	<div ref="list" :class="['c-picker-list', { active: active === true }]">
		<template v-if="list.length > 0">
			<div class="c-picker-list__input" v-if="boards.length > 10">
				<InputText v-model="query" placeholder="Search for boards"></InputText>
			</div>
			<div
				class="c-picker-list__item"
				v-for="item in list"
				:key="item.id"
				:style="item.style"
				:class="[{ active: item.active === true }]"
				:title="item.description || ''"
				tabindex="0"
				@keydown.space.prevent="toggleSelected(item)"
				@keydown.enter.prevent="toggleSelected(item)"
			>
				<div class="c-picker-list__clickable" @click.prevent="toggleSelected(item)">
					<article>
						<p>{{ item.name }}</p>
						<span> {{ item.createdAt }} </span>
					</article>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4 13L9 18L20 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</div>
				<!-- <div class="c-picker-list__default" @click="toggleDefault(item)">Set default?</div> -->
			</div>
		</template>
		<section v-if="list.length === 0" class="c-picker-list__empty-state">
			<p>No boards found. <a href="#" @click="onCreateBoard">Create one</a>.</p>
		</section>
	</div>
</template>

<script>
import InputText from '@swipekit/components/form/input-text';
import Fuse from 'fuse.js';
import { format } from 'date-fns';

export default {
	components: {
		InputText,
	},

	data: function () {
		return {
			query: '',
			loaded: false,
		};
	},

	props: {
		selected: {},
		baseUrl: {},
		active: {
			type: Boolean,
			default: false,
		},
	},

	watch: {
		active: function () {
			if (!this.active) {
				return;
			}

			if (this.loaded) {
				return;
			}

			if (this.active) {
				let selector = `.c-picker-list__input input`;
				let el = this.$el.querySelector(selector);
				if (el) {
					el.focus();
				}
			}
		},
	},

	computed: {
		boards: function () {
			return this.$store.getters['boards/filtered'];
		},
		list: function () {
			let selected = this.selected;

			let items = this.boards || [];

			items = JSON.parse(JSON.stringify(items));

			if (this.query) {
				let options = {
					keys: ['name'],
				};

				let fuse = new Fuse(items, options);

				items = fuse.search(this.query);

				items = items.map((item) => {
					return item.item;
				});
			} else {
				items = items.sort((a, b) => {
					return a.order - b.order;
				});
			}

			items = items.map((item) => {
				let i = selected.indexOf(item.id);

				item.active = false;

				item.createdAt = format(new Date(item.createdAt), `do MMM yyyy`);

				if (i === -1) {
				} else {
					item.active = true;
				}

				return item;
			});

			items = items.sort((a, b) => {
				return a.order - b.order;
			});

			return items;
		},
	},

	methods: {
		refresh: function () {
			//this.loadBoards();
		},
		onCreateBoard: function () {
			this.$emit('onCreateBoard');
		},
		toggleSelected: function (item) {
			let i = this.selected.indexOf(item.id);
			let selected = this.selected;
			if (i === -1) {
				selected.push(item.id);
			} else {
				let i = selected.indexOf(item.id);
				selected.splice(i, 1);
			}

			this.$emit('change', selected);
		},
		handleClickOutside(event) {
			if (!this.active) {
				return;
			}

			let condition = event.target.closest('.c-picker');

			if (!condition) {
				this.$emit('clickOutside');
			}

			// if (!condition) {
			//   this.$emit("clickOutside");
			// }
		},
	},

	mounted() {
		document.body.addEventListener('click', this.handleClickOutside);
	},
	beforeDestroy() {
		document.body.removeEventListener('click', this.handleClickOutside);
	},
};
</script>

<style></style>
