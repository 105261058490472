<template>
	<div class="c-workspace-billing">
		<div class="c-workspace-billing__details">
			<Box>
				<template v-slot:header>
					<h5>Billing Portal</h5>
				</template>
				<template v-slot:body>
					<template v-if="computedPlan !== 'Tester'">
						<Pricing v-if="!isPaid || subscriptionEndsIn"></Pricing>
					</template>
					<template v-if="isPaid">
						<div :class="['c-workspace-billing__bills']">
							<section v-if="isPaid">
								<article>
									<h5>Unsubscribe PRO subscription</h5>
									<p>Fill this form to cancel your Swipekit PRO subscription.</p>
								</article>
								<div v-if="!subscriptionEndsIn">
									<FormUnsubscribe></FormUnsubscribe>
									<br />
									<small> This will stop your subscription and you won't be charged your card anymore. Once you unsubscribe, you will get an email confirming your cancellation. </small>
								</div>
								<div v-else>
									<div class="info-label">
										<p>Your subscription has been cancelled and you won't be charged anymore. You will lose access to your account within {{ subscriptionEndsIn }} day(s).</p>
										<p>To resubscribe, click on the 'Upgrade' button above and resubscribe to one of our plans.</p>
									</div>
								</div>
							</section>
							<section>
								<article>
									<h5>Manage your Subscription</h5>
									<p>Update your card details from this portal.</p>
								</article>
								<div>
									<a class="btn btn-primary btn-lg" :href="`${baseUrl}/user/portal`" v-if="!user.isTrial && isWorkspaceAdmin" @click.prevent="gotoBillingPortal"> Billing portal </a>
									<span v-if="!isWorkspaceAdmin"> Only the Workspace Admin can update Billing details. </span>
								</div>
							</section>
							<!-- <section v-if="isPaid">
                <article>
                  <h5>Manage seat subscriptions</h5>
                </article>
                <p>To unsubscribe from a seat, let us know the email of the seat you wish to unsubscribe from</p>
              </section> -->
						</div>
						<p></p>
					</template>
					<template v-if="!isPaid">
						<div class="c-workspace-billing__account" v-if="computedPlan !== 'Deactivated'">
							<article class="article-text">
								You're on the {{ computedPlan }} plan.
								<span v-if="computedPlan === 'Tester'"> You have been granted free use of Swipekit. </span>
							</article>
						</div>
						<br />
					</template>
					<BillingFaqs></BillingFaqs>
				</template>
			</Box>

			<br />
			<br />

			<Box>
				<template v-slot:header>
					<h5>Invoices</h5>
				</template>
				<template v-slot:body>
					<WorkspaceInvoices></WorkspaceInvoices>
					<strong>Got issues?</strong><br />
					<a href="#" @click="onSupportClick">Contact support</a>
				</template>
			</Box>
		</div>
	</div>
</template>

<script>
import api from '@swipekit/lib/api';
import WorkspaceInvoices from '@swipekit/components/workspace/invoices';
import Box from '@swipekit/components/utils/box';
import FormUnsubscribe from '@swipekit/components/form/form-unsubscribe';
import FormUpdateCard from '@swipekit/components/form/form-update-card';
import BillingFaqs from './billing-faqs.vue';

import Pricing from './pricing.vue';
import { loadStripe } from '@stripe/stripe-js';

export default {
	components: {
		WorkspaceInvoices,
		Box,
		FormUnsubscribe,
		FormUpdateCard,
		BillingFaqs,
		Pricing,
	},
	data: function () {
		return {
			processing: {
				upgrade: false,
			},
		};
	},

	computed: {
		subscriptionEndsIn: function () {
			return this.$store.getters['workspaces/subscriptionEndsIn'];
		},
		isWorkspaceAdmin: function () {
			return this.$store.getters['workspaces/isAdmin'];
		},
		isPaid: function () {
			return this.$store.getters['users/isPaid'];
		},
		trialStartedDifference: function () {
			let diff = this.$store.getters['users/trialStartedDifference'];

			return diff;
		},
		isTrialOver: function () {
			return this.$store.getters['users/isTrialOver'];
		},
		baseUrl: function () {
			return this.$store.state.config.baseUrl;
		},
		user: function () {
			return this.$store.getters['users/user'];
		},
		workspace: function () {
			return this.$store.getters['workspaces/all'];
		},
		adsSaved: function () {
			let ads = this.$store.getters['ads/filtered'];

			return ads.length;
		},
		boardsSaved: function () {
			let boards = this.$store.getters['boards/filtered'];

			return boards.length;
		},
		computedPlan: function () {
			if (!this.workspace) {
				return '';
			}
			let plan = this.workspace.plan;

			if (!plan) {
				return '';
			}

			plan = plan.toLowerCase();

			plan = plan.charAt(0).toUpperCase() + plan.slice(1);

			return plan;
		},
	},

	methods: {
		async gotoBillingPortal() {
			let res = await api.users.portal();

			if (res.portalUrl) {
				window.open(res.portalUrl, '_blank').focus();
			}
		},
		onSupportClick: function () {
			$crisp.push(['do', 'chat:open']);
		},
		async upgrade() {
			this.processing.upgrade = true;
			await this.$store.dispatch('users/upgrade', 'monthly');
		},
	},

	mounted: async function () {
		// const stripe = await loadStripe(
		//   `pk_test_51Kqa46Hz7gs7nFkM8LfyoFZNt54aKaEO8RlNZ5atF6pDjbIsp7xjOwok1mf8pJT1MR8gBtRopZuKyl1Y0GgetVwN00532ZMGzF`
		// );
		// const elements = stripe.elements({
		//   clientSecret: `sk_test_51Kqa46Hz7gs7nFkMUaPNjX6LgghMtzw3037RASKigoMsOCnYoIkeSA0w1SlLz8Y3LEr5QpTZdcAHYTkyCEqxwE5R00QT5kyVWd`,
		// });
		// const paymentElement = elements.create("payment");
		// paymentElement.mount("#payment-element");
	},
};
</script>

<style lang="scss">
.c-workspace-billing {
	&__bills {
		section {
			display: grid;
			grid-template-columns: 1fr 1fr;
			padding-bottom: var(--spacer);

			&:not(:last-child) {
				border-bottom: var(--color-bg-4) solid 1px;
			}

			&:not(:first-child) {
				padding-top: var(--spacer);
			}
		}

		&.disabled {
			opacity: 0.65;
			pointer-events: none;
		}
	}

	.info-label {
		padding: var(--margin-lg);
		background-color: hsl(4, 95%, 96.5%);
		border-radius: 10px;
	}

	&__account {
		display: block;
		background-color: hsl(var(--p), 60%, 95%);
		border-radius: var(--border-radius);
		padding: var(--margin-lg);

		> article {
			margin-bottom: 0;
		}
	}
}
</style>
