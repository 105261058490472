<template>
	<div class="c-brand-slug-words">
		<strong> Timeline </strong>
		<Chart v-if="data" :data="data"></Chart>
	</div>
</template>

<script>
import Copy from '@swipekit/components/block-view/copy';
import Chart from '@swipekit/components/chart';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { start } from '@popperjs/core';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default {
	components: {
		Chart,
		Copy,
	},

	props: {
		insight: {},
		days: {
			default: 0,
		},
	},

	computed: {
		data: function () {
			let data = this.insight.chart;

			if (!data) {
				return [];
			}

			let newData = [];

			for (let i = 0; i < data.length; i++) {
				let obj = {
					x: i,
					y: data[i].count,
					date: data[i].date,
				};
				newData.push(obj);
			}

			newData = this.removeOldData(newData);

			newData = this.cleanData(newData);

			return newData;
		},
	},

	methods: {
		removeOldData: function (dataset) {
			const today = new Date();
			const cutoffDate = new Date(today);
			cutoffDate.setDate(today.getDate() - 60); // Subtract 60 days from today

			// Filter the dataset to remove entries older than 60 days
			const filteredDataset = dataset.filter((entry) => new Date(entry.date) >= cutoffDate);

			return filteredDataset;
		},
		cleanData: function (dataset) {
			const startDaysBack = this.days || 60;

			const parsedDates = dataset.map((entry) => {
				return {
					date: dayjs(entry.date, `YYYY-MM-DD`).toDate(),
					count: entry.y,
					rawDate: entry.date,
				};
			});

			// Calculate the start date
			const today = new Date();
			const startDate = new Date(today);
			startDate.setDate(today.getDate() - startDaysBack); // Subtract the specified number of days

			// Calculate the end date (today)
			const endDate = today;

			// Generate the full range of dates
			const allDates = [];
			let currentDate = new Date(startDate);

			while (currentDate <= endDate) {
				allDates.push(new Date(currentDate)); // Store a copy of the current date
				currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
			}

			// Create a new dataset with gaps filled
			const filledDataset = allDates.map((date, i) => {
				const dateString = date.toISOString().split('T')[0]; // Format date as 'YYYY-MM-DD'
				const found = parsedDates.find((entry) => entry.rawDate === dateString);
				let obj = {
					x: i,
					y: found ? found.count : 0,
					date: dateString,
				};

				return obj;
			});

			return filledDataset;
		},
	},
};
</script>

<style lang="scss">
.c-brand-slug-timeline {
	margin-top: var(--margin-lg);
}
</style>
