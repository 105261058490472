<template>
	<div class="c-onboarding2-tracker">
		<Page :i="5">
			<!-- <span class="c-byline"> Tutorial #5: Boards </span> -->
			<h1>Brand Tracker</h1>
			<article class="article-text">
				<p>Swipekit can track brands on the Facebook ad library on your behalf.</p>
				<ul class="fancy-list">
					<li>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
								fill="currentColor"
							></path>
						</svg>
						<p>No need to visit the Facebook ad library again and again - save scraped ads from Swipekit itself!</p>
					</li>
					<li>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.5694 9.48809C17.839 9.1736 17.8026 8.70012 17.4881 8.43056C17.1736 8.16099 16.7001 8.19741 16.4306 8.51191L10.9576 14.897L7.53033 11.4697C7.23744 11.1768 6.76256 11.1768 6.46967 11.4697C6.17678 11.7626 6.17678 12.2374 6.46967 12.5303L10.4697 16.5303C10.6174 16.6781 10.82 16.7575 11.0288 16.7494C11.2375 16.7414 11.4335 16.6467 11.5694 16.4881L17.5694 9.48809Z"
								fill="currentColor"
							></path>
						</svg>
						<p>Understand what your competition is up to. Swipekit will automatically generate insights for tracked brands.</p>
					</li>
				</ul>

				<hr />

				<p>Paste a link of the brand's Facebook ad library page and we'll track it for you.</p>
			</article>
			<FormBrandCreate @onSubmit="onClick"></FormBrandCreate>
			<hr />
			<a href="#" @click="onClick">Skip, I'll track one later.</a>
		</Page>
	</div>
</template>

<script>
import Page from './page';
import ButtonCta from './button-cta.vue';
import FormBrandCreate from '@swipekit/components/form/form-brand-create';
export default {
	components: {
		Page,
		ButtonCta,
		FormBrandCreate,
	},

	computed: {
		computedName: function () {
			if (!this.user) {
				return;
			}
			let name = `${this.user.name}'s first board`;

			return name;
		},
		user: function () {
			return this.$store.getters['users/user'];
		},
	},

	methods: {
		onClick: function (e) {
			if (e && !e.target) {
				this.$store.dispatch('message/sendMessage', {
					message: 'Tracking brand',
					status: 'success',
				});
			}
			this.$emit('onNext');
		},
	},
};
</script>

<style lang="scss">
.c-onboarding2-tracker {
}
</style>
