<template>
	<div class="c-block-view-copy" @click="onCopy">
		<Float ref="float"></Float>
		<slot>
			<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M16.1667 8V8C17.8741 8 18.7278 8 19.409 8.25638C20.4871 8.66213 21.3379 9.51294 21.7436 10.591C22 11.2722 22 12.1259 22 13.8333V15.6C22 17.8402 22 18.9603 21.564 19.816C21.1805 20.5686 20.5686 21.1805 19.816 21.564C18.9603 22 17.8402 22 15.6 22H13.8333C12.1259 22 11.2722 22 10.591 21.7436C9.51294 21.3379 8.66213 20.4871 8.25638 19.409C8 18.7278 8 17.8741 8 16.1667V16.1667M8.4 16H9.6C11.8402 16 12.9603 16 13.816 15.564C14.5686 15.1805 15.1805 14.5686 15.564 13.816C16 12.9603 16 11.8402 16 9.6V8.4C16 6.15979 16 5.03968 15.564 4.18404C15.1805 3.43139 14.5686 2.81947 13.816 2.43597C12.9603 2 11.8402 2 9.6 2H8.4C6.15979 2 5.03968 2 4.18404 2.43597C3.43139 2.81947 2.81947 3.43139 2.43597 4.18404C2 5.03968 2 6.15979 2 8.4V9.6C2 11.8402 2 12.9603 2.43597 13.816C2.81947 14.5686 3.43139 15.1805 4.18404 15.564C5.03968 16 6.15979 16 8.4 16Z"
					stroke="currentColor"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</slot>
	</div>
</template>

<script>
import Float from './float';

export default {
	components: {
		Float,
	},

	data: function () {
		return {
			processing: false,
		};
	},

	props: {
		text: {
			default: '',
		},
	},

	computed: {
		copyText: function () {
			return 'Copied!';
		},
	},

	methods: {
		onCopy: function () {
			this.processing = true;
			let text = this.text;

			// Copy the text inside the text field
			navigator.clipboard.writeText(text);

			this.$refs.float.addFloat();

			setTimeout(() => {
				this.processing = false;
			}, 1500);
		},
	},
};
</script>

<style lang="scss">
.c-block-view-copy {
	position: relative;
	cursor: pointer;
	opacity: 0.8;

	&:hover,
	&:active {
		opacity: 1;
		color: var(--color-primary);
	}
}
</style>
