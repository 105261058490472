<template>
	<div class="c-modal-reward">
		<Modal v-model="options.active" @input="closeModal" :closable="true">
			<h1>🎁</h1>
			<p class="article-text-lg">
				Extend your free trial by <strong>{{ trialLength }}</strong> days!
			</p>
			<p>
				If you're enjoying Swipekit, I'd love to see a review from you!
				<br />
				In return, I will extend your free trial by {{ trialLength }} days.
			</p>
			<p>Here's how it works:</p>
			<ul class="c-list-fancy">
				<li>
					<p>
						Post a review on our
						<a href="https://chrome.google.com/webstore/detail/ad-library-downloader/gojmmkhaiojimnnjhhilmhjmhdbdagod" target="_blank">Chrome webstore listing.</a>
						<br />Please post a sizeable review, eg: "{{ reviewExample }}". One word reviews aren't acceptable.
					</p>
				</li>
				<li>
					<p>
						Once the review is live, message me using the in-app live chat widget 'Hey I've posted a review under the name
						{{ user.name }}'
					</p>
				</li>
				<li>
					<p>
						I'll extend your free trial by <strong>{{ trialLength }}</strong> days.
					</p>
				</li>
			</ul>
			<a class="btn btn-lg btn-primary" href="https://chrome.google.com/webstore/detail/ad-library-downloader/gojmmkhaiojimnnjhhilmhjmhdbdagod" target="_blank"> Post a Review </a>
		</Modal>
	</div>
</template>

<script>
import Modal from './index.vue';

import { parseISO, format } from 'date-fns';

import BlockView from '@swipekit/components/block-view';

export default {
	components: {
		Modal,
		BlockView,
	},

	data: function () {
		return {
			trialLength: 14,

			options: {
				name: 'modal-block-view',
				active: false,
			},
		};
	},

	watch: {
		shareId: {
			handler: function () {
				if (this.shareId) {
					this.options.active = true;
				} else {
					this.options.active = false;
				}
			},
			immediate: true,
		},
	},

	computed: {
		reviewExample: function () {
			let arr = [
				'I love this tool for collecting inspo from the fb ad library.',
				'Quick and easy way to save any ad',
				'Been loving this extension, has made my workflow so much better!',
				'Great extension, highly recommend!',
				'Better than most that are twice or three times the price!',
				'Great app, would recommended it!',
				'This tool has made our ad organization workflow so much better.',
				'Can now save ads automatically via the tracker feature!',
			];

			let randomIndex = Math.floor(Math.random() * arr.length);

			// Get the random element from the array
			let randomElement = arr[randomIndex];

			return randomElement;
		},
		user: function () {
			return this.$store.getters['users/user'];
		},
		shareId: function () {
			return this.$store.getters['app/rewardTrialExtensionId'];
		},
	},

	methods: {
		closeModal: function () {
			this.$store.dispatch('app/setRewardTrialExtension', false);
			this.$emit('closeModal');
		},
	},

	mounted: function () {},
};
</script>

<style lang="scss">
.c-modal-reward {
	.modal__content {
		padding: var(--spacer);
		padding-top: 0;

		text-align: center;

		.article-text-lg {
			font-weight: 500;

			strong {
				font-weight: 700;
			}
		}

		ul {
			max-width: 380px;
			margin: 0 auto;
			margin-top: var(--spacer-sm);
			text-align: left;
		}
	}
}
</style>
