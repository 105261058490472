<template>
	<div v-if="isAllowed" :class="['c-item-copy', { expanded: expanded === true }]">
		<p v-if="adTitle">{{ adTitle }}</p>
		<article>{{ adCopy }}</article>
		<span v-if="showShowMore" class="click-prevent" @click.prevent="toggleExpansion"> {{ copyText }} </span>
		<div class="c-grid-item__label" v-if="labels">3</div>
	</div>
</template>

<script>
export default {
	data: function () {
		return {
			expanded: false,
		};
	},
	props: {
		labels: {
			type: Boolean,
			default: false,
		},
		block: {},
	},
	computed: {
		showShowMore: function () {
			let max = 114;
			if (this.adCopy && this.adCopy.length > max) {
				return true;
			}

			return false;
		},
		isAllowed: function () {
			if (this.block.type === 'tiktok-top-ads') {
				return false;
			}
			if (this.block.type === 'tiktok-ad-library') {
				return false;
			}
			if (this.block.type === 'tiktok') {
				return false;
			}
			if (!this.block.adCopy) {
				return false;
			}

			return true;
		},
		copyText: function () {
			if (this.expanded) {
				return 'Show less';
			}
			return 'Show more';
		},
		adTitle: function () {
			let title = this.block.adTitle;
			if (!title) {
				return '';
			}
			return title;
		},
		adCopy: function () {
			let copy = this.block.adCopy;

			return copy;
		},
	},
	methods: {
		toggleExpansion: function () {
			this.expanded = !this.expanded;

			this.$nextTick(() => {
				this.$redrawVueMasonry();
			});
		},
	},
};
</script>

<style lang="scss">
.c-item-copy {
	position: relative;
	padding: var(--margin) var(--margin);

	> p {
		margin: 0;
		font-size: var(--font-size-xs);

		white-space: break-spaces;
		position: relative;

		font-size: 13px;
		font-weight: 500;

		max-height: 35px;
		overflow: hidden;
		color: var(--color-font);
	}
	> article {
		font-size: var(--font-size-xs);

		white-space: break-spaces;
		position: relative;

		font-size: 13px;

		max-height: 35px;
		overflow: hidden;
		color: var(--color-font);

		// &:after {
		//   content: "";
		//   position: absolute;
		//   bottom: 0;
		//   left: 0;
		//   width: 100%;
		//   height: 2px;
		//   background-color: hsla(40, 90%, 70%, 1);
		//   opacity: 0;
		// }

		// &:hover,
		// &:active {
		//   background-color: hsla(40, 90%, 90%, 1);
		//   color: #000;

		//   &:after {
		//     opacity: 1;
		//   }
		// }
	}

	> span {
		display: block;
		font-size: 13px;
		margin-top: 2px;
		color: var(--color-primary);

		&:hover,
		&:active {
			text-decoration: underline;
		}
	}

	&.expanded {
		> article {
			max-height: initial;
		}
	}
}
</style>
