<template>
	<div class="c-workspace-user-stats">
		<div class="c-spinner" v-if="!loaded"></div>
		<div class="c-table" v-if="stats">
			<table>
				<tbody>
					<tr>
						<td>Your email</td>
						<td>
							{{ user.email }}
						</td>
					</tr>
					<tr>
						<td>Plan type</td>
						<td>
							{{ computedPlan }}
						</td>
					</tr>
					<tr v-if="workspace">
						<td>Workspace name</td>
						<td>
							{{ workspace.name }}
						</td>
					</tr>
					<tr v-if="workspace">
						<td>Account activated?</td>
						<td>
							{{ workspace.activated ? 'Yes' : 'No' }}
						</td>
					</tr>
					<tr>
						<td>Member since</td>
						<td>
							{{ computedMemberSince }}
						</td>
					</tr>
					<tr>
						<td>Total Ads in your library</td>
						<td>
							{{ stats.totalAds }}
						</td>
					</tr>
					<tr>
						<td>Total boards in your library</td>
						<td>
							{{ stats.totalBoards }}
						</td>
					</tr>
					<tr>
						<td>Tracker limit</td>
						<td>
							{{ workspace.trackerLimit }}
						</td>
					</tr>
					<tr>
						<td>Chrome extension link</td>
						<td>
							<a target="_blank" href="https://chrome.google.com/webstore/detail/facebook-tiktok-linkedin/gojmmkhaiojimnnjhhilmhjmhdbdagod">
								https://chrome.google.com/webstore/detail/facebook-tiktok-linkedin/gojmmkhaiojimnnjhhilmhjmhdbdagod
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import api from '@swipekit/lib/api';

import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
export default {
	data: function () {
		return {
			stats: null,
			loaded: false,
		};
	},

	computed: {
		workspace: function () {
			return this.$store.getters['workspaces/all'];
		},
		user: function () {
			return this.$store.getters['users/user'];
		},
		computedPlan: function () {
			if (!this.workspace) {
				return '';
			}
			let plan = this.workspace.plan;

			if (!plan) {
				return '';
			}

			plan = plan.toLowerCase();

			plan = plan.charAt(0).toUpperCase() + plan.slice(1);

			return plan;
		},
		computedMemberSince: function () {
			let createdAt = this.user.createdAt;

			let date = parseISO(createdAt);

			let str = format(date, 'do MMM, yyyy');

			return str;
		},
	},

	methods: {
		loadStats: async function () {
			this.loaded = false;
			const response = await api.users.stats();
			if (!response) {
				this.loaded = true;
				return;
			}
			this.stats = response.data;
			this.loaded = true;
		},
	},

	mounted: function () {
		this.loadStats();
	},
};
</script>

<style lang="scss">
.c-workspace-user-stats {
	display: flex;
	align-items: center;
	justify-content: center;

	.c-spinner {
		border: 2px solid var(--color-font);
		border-bottom-color: transparent;
	}

	.c-table {
		tr {
			grid-template-columns: 200px 1fr;
		}
	}
}
</style>
