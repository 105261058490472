import config from './store/config.js';
import app from './store/app.js';
import ads from './store/ads.js';
import boards from './store/boards.js';
import users from './store/users.js';
import workspaces from './store/workspaces.js';
import comments from './store/comments.js';
import events from './store/events.js';
import message from './store/message.js';
import brands from './store/brands.js';

export const store = {
	config,
	app,
	ads,
	boards,
	users,
	workspaces,
	comments,
	events,
	message,
	brands,
};
