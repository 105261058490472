<template>
	<div class="c-modal-block-view">
		<Modal v-model="options.active" @input="closeModal" :closable="true">
			<BlockView v-if="ad" :latestAd="ad" @onSubmit="onSubmit" @afterDelete="afterDelete"></BlockView>
			<div class="c-spinner" v-if="processing"></div>
			<article v-if="error" class="c-modal-block-view__article article-text-lg">
				<p>Ad couldn't be loaded.</p>
			</article>
		</Modal>
	</div>
</template>

<script>
import Modal from './index.vue';

import { parseISO, format } from 'date-fns';

import BlockView from '@swipekit/components/block-view';

import api from '@swipekit/lib/api';

export default {
	components: {
		Modal,
		BlockView,
	},

	data: function () {
		return {
			lock: false,
			error: false,
			options: {
				name: 'modal-block-view',
				active: false,
			},
			ad: null,
			forceRender: true,
			processing: false,
		};
	},

	props: {
		standalone: {
			type: Boolean,
			default: false,
		},
	},

	watch: {
		'options.active': {
			handler: function () {},
		},
		viewId: function () {
			this.error = false;
			if (this.viewId) {
				this.options.active = true;
				this.loadAd();
				this.lock = true;
				setTimeout(() => {
					this.lock = false;
				}, 500);
			} else {
				this.options.active = false;
			}
		},
		block: function () {
			this.forceRender = false;
			this.$nextTick(() => {
				this.forceRender = true;
			});
		},
	},

	computed: {
		sourceUrl: function () {
			let block = this.ad.block;

			if (block.source && typeof block.source === 'string') {
				return block.source;
			}

			return null;
		},
		viewCache: function () {
			return this.$store.getters['ads/viewCache'];
		},
		createdAt: function () {
			let createdAt = this.block.createdAt;
			let date = parseISO(createdAt);
			let str = format(date, `do MMM yy`);
			return str;
		},

		viewId: function () {
			return this.$store.getters['ads/viewId'];
		},
		text: function () {
			return this.block.title;
			let type = this.block.type;

			if (type === 'email') {
				return 'Email';
			}
			if (type === 'webpage') {
				return 'Webpage';
			}
			if (type === 'webpageSelection') {
				return 'Webpage section';
			}
			if (type === 'facebook') {
				return 'Facebook Ad library';
			}

			return '';
		},
	},

	methods: {
		loadAd: async function () {
			this.ad = null;
			this.processing = true;

			const adId = this.viewId;

			let response = null;

			if (this.standalone) {
				response = await api.api.getOne(adId);
			} else {
				response = await api.ads.getOne(adId);
			}

			if (response) {
				this.error = false;
				this.ad = response;
			} else {
				this.error = true;
			}
			this.processing = false;
		},
		navigate: function (direction) {
			let viewCache = this.viewCache;
			let ids = viewCache.map((block) => {
				return block.id;
			});

			if (ids.length < 1) {
				return;
			}

			let i = ids.indexOf(this.block.id);

			if (i === -1) {
				return;
			}

			if (direction === 'prev') {
				if (i === 0) {
					i = ids.length - 1;
				} else {
					i = i - 1;
				}
			}
			if (direction === 'next') {
				if (i === ids.length - 1) {
					i = 0;
				} else {
					i = i + 1;
				}
			}

			let id = ids[i];

			this.$store.dispatch('ads/setView', id);
		},
		onSubmit: function () {
			this.options.active = false;
		},
		activate: function () {
			this.options.active = true;
		},
		closeModal: function () {
			if (this.lock) {
				return;
			}
			this.$store.dispatch('ads/setView', null);
		},
		afterDelete: function () {
			this.closeModal();
		},
	},

	mounted: function () {
		//this.options.active = true;
	},
};
</script>

<style lang="scss">
.c-modal-block-view {
	.vfm__overlay {
		background-color: hsla(var(--s), 35%, 7%, 0.75) !important;

		&.non-closable {
		}
	}
	.modal-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		overflow-y: auto;
		padding: 0;
	}

	.modal-content {
		width: 1200px;
		height: 980px;
		max-height: 100%;
		margin: 0;

		//overflow: hidden;;
	}

	.modal__title {
		padding: 0;
	}

	.modal__content {
		padding: 0;
		height: 100%;
		border-radius: 10px !important;
		background-color: var(--color-bg-2);

		> .c-spinner {
			position: absolute;
			top: calc(50% - 24px);
			left: calc(50% - 24px);
			width: 48px;
			height: 48px;
			border-width: 4px;
			border-color: #000;
			border-bottom-color: transparent;
		}
	}

	&__article {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@media screen and (max-width: 1400px) {
		.modal-content {
			height: 92%;
		}
	}

	@media screen and (max-width: 996px) {
		.modal-content {
			margin-top: 0;
			width: 100%;
			height: 100%;
		}
	}

	@media screen and (min-width: 1800px) {
		.modal-content {
			width: 1440px;
			height: 1200px;
		}
	}
}
</style>
